<template>
  <div>
    <button @click="toogleMap" class="gallery-item rounded-3 no-border">
      <i
        class="bx bxs-map text-primary display-5 position-absolute top-50 start-50 translate-middle mb-0"
      ></i>
      <img src="../assets/img/landing/medical/map.png" alt="Map cover" />
      <div class="gallery-item-caption fs-sm fw-medium">Expand the map</div>
    </button>

    <!-- Modal for the map -->
    <div v-if="showMapModal" class="map-container">
      <div class="modaldialog">
        <div class="modal-header">
          <h5 class="modal-title">Qhala</h5>
          <button type="button" class="close" @click="closeMapModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            style="border: 0"
            :src="mapUrl"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import MapView from "./MapView.vue";

export default {
  components: {
    //MapView,
  },
  data() {
    return {
      showMapModal: false,
    };
  },
  props: {
    mapUrl: String, // Pass the map URL as a prop
  },
  methods: {
    checkstate() {
      if (this.showMapModal === true) {
        console.log("maptrue", this.showMapModal);
      } else {
        print("nothing ");
      }
    },
    toogleMap() {
      this.showMapModal = !this.showMapModal;
      console.log("mapModal", this.showMapModal);
    },
    closeMapModal(){
      this.showMapModal = false;
    }
  },
};
</script>

<style>
.no-border {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.map-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ); /* Optional: Add a semi-transparent background */
  z-index: 1000; /* Adjust the z-index to ensure the div overlays other content */
}
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: auto;
}

/* Styling for the modal dialog */
.modaldialog {
  position: relative;
  padding: 0; /* Remove padding */
  width: 100%; /* Set the modal width to 90% of the screen width */
  max-width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

/* Styling for the modal header */
.modal-header {
  padding: 0.5rem;
  background-color: rgb(25, 25, 25);
  color: #fff;
  border-bottom: 1px solid #dee2e6;
  position: relative;
}

/* Styling for the modal title */
.modal-title {
  margin: 0;
  text-align: center;
}

/* Styling for the close button */
.close {
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
  font-size: 2rem;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background: none; /* Remove background */
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
}

/* Styling for the modal body */
.modal-body {
  padding: 0; /* Remove padding to make the iframe take the entire space */
}
/* Styling for the iframe inside the modal body */
.modal-body iframe {
  width: 100%;
  height: 90vh; /* Set the iframe height to 90% of the viewport height */
  border: 0;
}
</style>
