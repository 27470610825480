<template>
  <div class="container">
    <div class="column column1"></div>
    <div class="column">
      <div >
        <div class="canceling" @click="gotoprivacy">
          <img src="../assets/img/deleteAc/x-close.png" />
        </div>
        <div>
          <div class="reviewtext">
            Please take a moment to rate your overall experience with <br />
            Tibalink. Your rating will help us understand how we can improve
            <br />our services to better meet your needs.
          </div>
        </div>
        <div class="rating">
          <div class="star-image">
            <div @click="getrating(1)" v-if="!oneStar" class="imagepos">
              <img src="../assets/img/deleteAc/star1.png" />
            </div>
            <div @click="getrating(1)" v-if="oneStar" class="imagepos">
              <img src="../assets/img/deleteAc/star2.png" />
            </div>
          </div>
          <div class="star-image">
            <div @click="getrating(2)" v-if="!twoStar" class="imagepos">
              <img src="../assets/img/deleteAc/star1.png" />
            </div>
            <div @click="getrating(2)" v-if="twoStar" class="imagepos">
              <img src="../assets/img/deleteAc/star2.png" />
            </div>
          </div>
          <div class="star-image">
            <div @click="getrating(3)" v-if="!threeStar" class="imagepos">
              <img src="../assets/img/deleteAc/star1.png" />
            </div>
            <div @click="getrating(3)" v-if="threeStar" class="imagepos">
              <img src="../assets/img/deleteAc/star2.png" />
            </div>
          </div>
          <div class="star-image">
            <div @click="getrating(4)" v-if="!fourStar" class="imagepos">
              <img src="../assets/img/deleteAc/star1.png" />
            </div>
            <div @click="getrating(4)" v-if="fourStar" class="imagepos">
              <img src="../assets/img/deleteAc/star2.png" />
            </div>
          </div>
          <div class="icons">
            <div @click="getrating(5)" v-if="!fiveStar" class="imagepos">
              <img src="../assets/img/deleteAc/star1.png" />
            </div>
            <div @click="getrating(5)" v-if="fiveStar" class="imagepos">
              <img src="../assets/img/deleteAc/star2.png" />
            </div>
          </div>
        </div>
        <div class="nav">
          <div class="navprev">
            <img src="../assets/img/deleteAc/right.png" @click="gotoprev" />
            <div class="navtext" @click="gotoprev">Back</div>
          </div>
          <div class="navnext">
            <div class="navtext" @click="gotnext">Proceed</div>
            <img src="../assets/img/deleteAc/left.png" @click="gotnext" />
          </div>
        </div>
        <div class="logo"><img src="../assets/img/logo.svg" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oneStar: false,
      twoStar: false,
      threeStar: false,
      fourStar: false,
      fiveStar: false,
      rating: null,
    };
  },
  methods: {
    clickIcon() {
      this.selectedOption1 = !this.selectedOption1;
    },
    gotnext() {
      this.$store.dispatch("review",{data:this.rating});
      this.$router.push({ name: "ootpPage" });
    },
    gotoprev() {
      this.$router.push({ name: "reviewpage" });
    },
    gotoprivacy() {
      this.$router.push({ name: "privacypolicy" });
    },
    getrating(num) {
      if (num === 5) {
          (this.oneStar = true),
          (this.twoStar = true),
          (this.threeStar = true),
          (this.fourStar = true),
          (this.fiveStar = true);
          this.rating = 5;
      }
      if (num === 4) {
        (this.oneStar = true),
          (this.twoStar = true),
          (this.threeStar = true),
          (this.fourStar = true),
          (this.fiveStar = false),
          (this.rating = 4);
      }
      if (num === 3) {
        (this.oneStar = true),
          (this.twoStar = true),
          (this.threeStar = true),
          (this.fourStar = false),
          (this.fiveStar = false),
          (this.rating = 3);
      }
      if (num === 2) {
        (this.oneStar = true),
          (this.twoStar = true),
          (this.threeStar = false),
          (this.fourStar = false),
          (this.fiveStar = false),
          (this.rating = 2);
      }
      if (num === 1) {
        (this.oneStar = true),
          (this.twoStar = false),
          (this.threeStar = false),
          (this.fourStar = false),
          (this.fiveStar = false),
          (this.rating = 1);
      }
    },
  },
};
</script>
<style scoped>
@media (min-width: 992px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .column {
    padding: 20px;
  }
  .column1 {
    background: url("../assets/img/deleteAc/review.png");
  }
  .rating {
    margin-top:4%;
    margin-left: 0% ;
    display: flex;
    width: 100%;
    position: absolute;
  }
  .star-image {
    margin-right: 3%;
  }
  .reviewtext {
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }
  .canceling {
    margin-left:80%;
    margin-top:5%;
    margin-bottom:5%;

  }
  .logo {
    margin-top:24.9%;
    margin-left:30%

  }

  .navprev {
    display: flex;
  }

  .navnext {
    margin-left: 30%;
    display: flex;
  }

  .nav {
    display: flex;
    margin-left: 5%;
    margin-top: 76.5%;
  }

  .navtext {
    color: #006466;
    font-size: 28.99px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }
  .textarea {
    position: relative;
  }
  .navtext:hover {
    color:rgb(69,211,176)
  }
}
@media (max-width: 992px) {

  .rating {
    margin-top:10%;
    margin-left: 15% ;
    display: flex;
    width: 100%;
    position: absolute;
  }
  .star-image {
    margin-right: 3%;
  }
  .reviewtext {
    display: flex;
    text-align: center;
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }
  .canceling {
    margin-left:70%;
    margin-top:5%;
    margin-bottom:5%;

  }
  .logo {
    margin-top:10%;
    margin-left:25%

  }

  .navprev {
    display: flex;
  }

  .navnext {
    margin-left: 30%;
    display: flex;
  }

  .nav {
    display: flex;
    margin-left: 5%;
    margin-top: 50%;
  }

  .navtext {
    color: #006466;
    font-size: 28.99px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }
  .textarea {
    position: relative;
  }
  .navtext:hover {
    color:rgb(69,211,176)
  }
}
@media (max-width: 675px) {

.rating {
  margin-top:20%;
  margin-left: 10% ;
  display: flex;
  width: 100%;
  position: absolute;
}
.star-image {
  margin-right: 3%;
}
.reviewtext {
  display: flex;
  text-align: center;
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}
.canceling {
  margin-left:70%;
  margin-top:5%;
  margin-bottom:5%;

}
.logo {
  margin-top:15%;
  margin-left:30%

}

.navprev {
  display: flex;
}

.navnext {
  margin-left: 27%;
  display: flex;
}

.nav {
  display: flex;
  margin-left: 5%;
  margin-top: 117.5%;
}

.navtext {
  color: #006466;
  font-size: 28.99px;
  font-family: Manrope;
  font-weight: 600;
  word-wrap: break-word;
}
.textarea {
  position: relative;
}
.navtext:hover {
    color:rgb(69,211,176)
  }
}
@media (max-width: 375px) {

.rating {
  margin-top:20%;
  margin-left: 5% ;
  display: flex;
  width: 100%;
  position: absolute;
}
.star-image {
  margin-right: 3%;
}
.reviewtext {
  display: flex;
  text-align: center;
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}
.canceling {
  margin-left:70%;
  margin-top:5%;
  margin-bottom:5%;

}
.logo {
  margin-top:20%;
  margin-left:30%

}

.navprev {
  display: flex;
}

.navnext {
  margin-left: 10%;
  display: flex;
}

.nav {
  display: flex;
  margin-left: 6%;
  margin-top: 58%;
}

.navtext {
  color: #006466;
  font-size: 28.99px;
  font-family: Manrope;
  font-weight: 600;
  word-wrap: break-word;
}
.textarea {
  position: relative;
}
.navtext:hover {
    color:rgb(69,211,176)
  }
}
@media (max-width: 320px) {

.rating {
  margin-top:20%;
  margin-left: 0% ;
  display: flex;
  width: 100%;
  position: absolute;
}
.star-image {
  margin-right: 1%;
}
.reviewtext {
  display: flex;
  text-align: center;
  color: black;
  font-size: 15px;
  font-family: Manrope;
  font-weight: 50;
  word-wrap: break-word;
}
.canceling {
  margin-left:70%;
  margin-top:5%;
  margin-bottom:5%;

}
.logo {
  margin-top:20%;
  margin-left:30%

}

.navprev {
  display: flex;
}

.navnext {
  margin-left: 10%;
  display: flex;
}

.nav {
  display: flex;
  margin-left: 6%;
  margin-top: 65%;
}

.navtext {
  color: #006466;
  font-size: 24.99px;
  font-family: Manrope;
  font-weight: 600;
  word-wrap: break-word;
}
.textarea {
  position: relative;
}
.navtext:hover {
    color:rgb(69,211,176)
  }
}
</style>
