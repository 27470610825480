<template>
  <div class="container">
    <div class="column column1"></div>
    <div class="column column2">
      <div>
        <div class="canceling" @click="gotoprivacy">
          <img src="../assets/img/deleteAc/x-close.png" />
        </div>
        <div>
          <div class="reviewtext">
            To proceed with the account deletion process, please <br />provide
            the phone number associated with your Tibalink <br />account:
          </div>
        </div>
        <div class="otpinput">
          <vue-tel-input
            v-model="phonenumber"
            @country-changed="onCountryChanged"
          ></vue-tel-input>
          <div style="margin-left: 8%" @click="checkUser" v-if="canverify">
            <a
              class="btn btn-hov btn-primary btn-lg"
              style="
                background-color: rgb(29, 211, 176);
                box-shadow: 2px 2px 5px rgba(29, 211, 176, 0.5);
              "
            >
              verify
            </a>
          </div>
        </div>
        <div v-if="!DisableVerification" style="color: red">
          Try again in {{ minutes }}:{{ seconds }}
        </div>
        <div
          style="color: red; margim-bottom: 10%"
          v-if="this.$store.state.accountntf"
        >
          Account not found ! <br />
          Check the phone entered and please try again
        </div>
        <div
          style="color: green; margim-bottom: 10%"
          v-if="this.$store.state.accountexists"
        >
          Verification successfully
        </div>
        <div class="guidetext">
          Upon submission, an OTP (One-Time Password) will be sent <br />
          to the provided phone number
        </div>
        <div class="spinner" v-if="this.$store.state.loading">
          <div
            class="spinner-border"
            style="
              width: 3rem;
              height: 3rem;
              color: black;
              border-radius: 50%;
              color: black;
            "
            role="status"
          >
            <span class="sr-only"></span>
          </div>
          <div class="spinnertext">Please wait this may take a moment....</div>
        </div>

        <div class="nav">
          <div class="navprev">
            <img src="../assets/img/deleteAc/right.png" @click="gotoprev" />
            <div class="navtext" @click="gotoprev">Back</div>
          </div>
          <div class="navnext" v-if="this.$store.state.accountexists">
            <div class="navtext" @click="sendootp">Proceed</div>
            <img src="../assets/img/deleteAc/left.png" @click="sendootp" />
          </div>
        </div>
        <div class="logo"><img src="../assets/img/logo.svg" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    // spinner,
  },
  data() {
    return {
      phonenumber: null,
      attemptCount: 0,
      isLoading: false,
      successiful: false,
      canverify: true,
      recaptchaVerifier: null,
      failed: false,
      timeout: null,
      minutes: 0,
      seconds: 0,
      remainingTime: 30000,
      DisableVerification: true,
    };
  },
  onMounted() {
    this.$store.state.accountntf = false;
    this.$store.state.accountexists = false;
    this.$store.state.accountexists = false;
  },
  created() {
    const storedAttemptCount = localStorage.getItem("attemptCount");
    let storedCanVerify = localStorage.getItem("canVerify");
    // to change the string to update the value for verify if a reload is done
    if (storedCanVerify === null || storedCanVerify === undefined) {
      this.canverify = true;
      localStorage.setItem("canVerify", true);
    } else if (storedCanVerify === "true") {
      this.canverify = true;
    } else if (storedCanVerify === "false") {
      this.canverify = false;
    }
    //check the number of attempts done and update the value
    if (storedAttemptCount) {
      this.attemptCount = parseInt(storedAttemptCount, 10);
    }
  },
  watch: {
    attemptCount() {
      localStorage.setItem("attemptCount", this.attemptCount);
    },
  },

  methods: {
    handlecounter() {
      this.attemptCount++;
      localStorage.setItem("attemptCount", this.attemptCount);
      const canVerify = localStorage.getItem("canVerify");
      console.log(
        `the value for verify condition ${canVerify} number of counts ${this.attemptCount}`
      );

      if (this.attemptCount >= 4) {
        this.DisableVerification = false;
        this.disableButton();
        localStorage.setItem("canVerify", false);
        this.startTimer();
      }
    },
    startTimer() {
      let startTime = localStorage.getItem("startTime");
      if (!startTime) {
        startTime = Date.now();
        localStorage.setItem("startTime", startTime);
      } else {
        const elapsedTime = Date.now() - startTime;
        this.remainingTime -= elapsedTime;
        if (this.remainingTime <= 0) {
          localStorage.removeItem("startTime");
          return;
        }
      }

      const updateTimer = () => {
        this.minutes = Math.floor(this.remainingTime / 60000);
        this.seconds = Math.floor((this.remainingTime % 60000) / 1000);
        this.remainingTime -= 1000;
        if (this.remainingTime < 0) {
          clearTimeout(this.timeout);
          this.canverify = true;
          // this.$store.state.accountexists = null;
          // this.$store.state.accountntf = null;
          this.$router.push({ name: "home" });
          localStorage.setItem("canVerify", true);
          this.enableButton();
          // Clear localStorage after time has elapsed
          localStorage.removeItem("startTime");
        } else {
          this.timeout = setTimeout(updateTimer, 1000);
        }
      };

      updateTimer();
    },
    disableButton() {
      localStorage.setItem("canVerify", false);
      localStorage.setItem("attemptCount", 0);
      this.canverify = false;
      this.attemptCount = 0;
    },
    enableButton() {
      this.DisableVerification = true;
      this.canverify = true;
      localStorage.setItem("attemptCount", 0);
      localStorage.setItem("canVerify", true);
      clearTimeout(this.timeout);
      this.timeout = null;
    },
    handleOTP(otp) {
      this.otpValue = otp;
    },
    clickIcon() {
      this.selectedOption1 = !this.selectedOption1;
    },
    checkUser() {
      this.handlecounter();
      const number = this.phonenumber.replace(/\s/g, "");
      this.$store.dispatch("checkUser", { phoneNumber: number });
    },
    sendootp() {
      const number = this.phonenumber.replace(/\s/g, "");
      this.$store.dispatch("sendootp", { phoneNumber: number });
      this.$router.push({ name: "accountdelete" });
    },
    gotoprev() {
      this.$router.push({ name: "ratingpage" });
    },
    gotoprivacy() {
      this.$router.push({ name: "privacypolicy" });
    },
    onCountryChanged(country) {
      this.phonenumber = "+" + country.dialCode;
    },
  },
};
</script>
<style scoped>
@media (min-width: 992px) {
  .otpinput {
    display: flex;
  }

  .verify {
    margin-left: 2%;
  }

  .otherstext {
    display: flex;
    max-width: 80%;
    margin-top: 5%;
  }

  .negative {
    position: relative;
    border: 5px solid #dd1f1f;
    border-radius: 10px;
    margin-top: 20%;
    margin-left: 15%;
  }

  .notification {
    position: relative;
    border: 5px solid #e9fbf8;
    border-radius: 10px;
    margin-top: 20%;
    margin-left: 15%;
  }

  .spinnertext {
    margin-left: 7%;
    color: black;
    font-size: 20px;
  }

  .spinner {
    display: flex;
    margin-top: 15%;
    margin-left: 10%;
  }

  .otherstext {
    max-width: 70%;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .column {
    padding: 20px;
  }

  .column1 {
    background: url("../assets/img/deleteAc/ootp.png");
  }

  .column2 {
    position: relative;
  }

  .guidetext {
    margin-top: 3%;
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .reviewtext {
    margin-bottom: 5%;
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .canceling {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 80%;
  }

  .logo {
    margin-top: 84%;
    margin-left: 30%;
  }

  .navtext {
    color: #006466;
    font-size: 28.99px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .navprev {
    display: flex;
    left: 10%;
  }

  .navnext {
    display: flex;
    position: absolute;
    left: 270%;
  }

  .nav {
    position: absolute;
    top: 68%;
    left: 11%;
    display: flex;
  }
  .navtext:hover {
    color: rgb(69, 211, 176);
  }
}

@media (max-width: 992px) {
  .otpinput {
    display: flex;
    margin-bottom: 10%;
  }

  .otherstext {
    display: flex;
    max-width: 70%;
    margin: 5%;
  }

  .guidetext {
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .reviewtext {
    margin-bottom: 10%;
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .canceling {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 80%;
  }

  .logo {
    margin-top: 10%;
    margin-left: 30%;
  }

  .navtext {
    color: #006466;
    font-size: 28.99px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .navprev {
    display: flex;
  }

  .navnext {
    margin-left: 30%;
    display: flex;
  }

  .nav {
    display: flex;
    margin-left: 5%;
    margin-top: 20%;
  }
  .navtext:hover {
    color: rgb(69, 211, 176);
  }
}

@media (max-width: 675px) {
  .otpinput {
    display: flex;
    margin-bottom: 10%;
  }

  .otherstext {
    max-width: 70%;
    margin: 5%;
  }

  .guidetext {
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .reviewtext {
    margin-bottom: 10%;
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .canceling {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 80%;
  }

  .logo {
    margin-top: 15%;
    margin-left: 30%;
  }

  .navtext {
    color: #006466;
    font-size: 28.99px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .navprev {
    display: flex;
  }

  .navnext {
    margin-left: 27%;
    display: flex;
  }

  .nav {
    display: flex;
    margin-top: 65%;
  }
  .navtext:hover {
    color: rgb(69, 211, 176);
  }
}

@media (max-width: 400px) {
  .otpinput {
    display: flex;
    margin-bottom: 10%;
  }

  .otherstext {
    max-width: 70%;
    margin: 5%;
  }

  .spinner {
    display: flex;
    margin-top: 15%;
    margin-left: 10%;
  }

  .spinnertext {
    margin-left: 5%;
    color: black;
  }

  .guidetext {
    color: black;
    font-size: 15px;
    font-family: Manrope;
    font-weight: 50;
    word-wrap: break-word;
  }

  .reviewtext {
    margin-bottom: 10%;
    color: black;
    font-size: 15px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .canceling {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 80%;
  }

  .logo {
    margin-top: 15%;
    margin-left: 30%;
  }

  .navtext {
    color: #006466;
    font-size: 28.99px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .navprev {
    display: flex;
  }

  .navnext {
    margin-left: 10%;
    display: flex;
  }

  .nav {
    display: flex;
    margin-top: 30%;
  }
  .navtext:hover {
    color: rgb(69, 211, 176);
  }
}
@media (max-width: 300px) {
  .otpinput {
    display: flex;
    margin-bottom: 10%;
  }

  .otherstext {
    max-width: 70%;
    margin: 5%;
  }

  .spinner {
    display: flex;
    margin-top: 15%;
    margin-left: 10%;
  }

  .spinnertext {
    margin-left: 5%;
    color: black;
  }

  .guidetext {
    color: black;
    font-size: 1px;
    font-family: Manrope;
    font-weight: 50;
    word-wrap: break-word;
  }

  .reviewtext {
    margin-bottom: 10%;
    color: black;
    font-size: 1px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .canceling {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 80%;
  }

  .logo {
    margin-top: 15%;
    margin-left: 30%;
  }

  .navtext {
    color: #006466;
    font-size: 2.99px;
    font-family: Manrope;
    font-weight: 0;
    word-wrap: break-word;
  }

  .navprev {
    display: flex;
  }

  .navnext {
    margin-left: 10%;
    display: flex;
  }

  .nav {
    display: flex;
    margin-top: 30%;
  }
  .navtext:hover {
    color: rgb(69, 211, 176);
  }
}
</style>
