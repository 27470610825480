<template>
  <div class="container">
    <div class="column clomun1">
      <!-- <img src="../assets/img/deleteAc/delete1.png" /> -->
    </div>
    <div class="column">
      <div>
        <div class="canceling" @click="gotoprivacy">
          <img src="../assets/img/deleteAc/x-close.png" />
        </div>
        <div>
          <div class="reviewtext">
            Please enter the OTP sent to your phone to confirm <br/>the account deletion
          </div>
        </div>
        <div>
          <div>
            <div class="otp-input">
              <input
                type="text"
                v-for="(digit, index) in otpDigits"
                :key="index"
                v-model="otp[index]"
                maxlength="1"
                @input="handleInput(index)"
                :ref="`otpInput${index}`"
              />
            </div>
          </div>
          <div
            style="color: red; margim-bottom: 10%"
            v-if="this.$store.state.invalidCode"
          >
            Verification failed please try again
          </div>
          <div class="inputtimer">
            <div v-if="this.$store.state.starttimer" style="color: red">
              Didn't receive your OTP? Resend in {{ countdown  }} seconds.
            </div>
          </div>
          <div
            v-if="!this.$store.state.starttimer"
            class="resettext"
            @click="gotoresend"
          >
            Resend the OTP
          </div>
          <div v-if="this.otpnotsent" style="color: red">
            Resend OTP failed.
          </div>
          <div v-if="this.otpsent" style="color: green">
            OTP resent succecifully .
          </div>
          <div
            id="recaptcha-container"
            style="width: 300px; margin: auto"
          ></div>
        </div>
        <div class="spinner" v-if="this.$store.state.delloading">
          <div
            class="spinner-border"
            style="
              width: 2.9rem;
              height: 3rem;
              color: black;
              border-radius: 50%;
              color: black;
            "
            role="status"
          >
            <span class="sr-only"></span>
          </div>
          <div class="spinnertext">Verifying OTP..</div>
        </div>
        <div class="deletebtn" v-if="this.$store.state.otpConfirmed">
          <a
            @click="deleteAC"
            class="btn btn-hov btn-primary btn-lg"
            style="
              background-color: rgb(29, 211, 176);
              box-shadow: 2px 2px 5px rgba(29, 211, 176, 0.5);
            "
          >
            Delete Account
          </a>
        </div>
        <div class="deleted" v-if="this.$store.state.accountDeleted">
          <div style="">
            <img src="../assets/img/deleteAc/tick2.png" />
          </div>
          <div class="deletedCanceling" @click="gotoprivacy" >
            <img src="../assets/img/deleteAc/x-close.png" />
          </div>
          <div class="deletedtextfield">
            <div class="deletedtext1">Account deleted successfully!</div>
            <div class="deletedtext2">Thankyou for being part of Tibalink.</div>
          </div>
        </div>
        <div class="deleted" v-if="this.$store.state.accountNotDelted">
          <div style="">
            <img src="../assets/img/deleteAc/negative.png" />
          </div>
          <div class="deletedCanceling" @click="gotoprivacy" >
            <img src="../assets/img/deleteAc/x-close.png" />
          </div>
          <div class="deletedtextfield">
            <div class="deletedtext1">Account not deleted</div>
            <div class="deletedtext2">Please try again later !</div>
          </div>
        </div>
        <div class="logo"><img src="../assets/img/logo.svg" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain:process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL:process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECT_Id,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_Id,
  appId: process.env.VUE_APP_APP_Id,
  measurementId: process.env.VUE_APP_MEASUREMENT_Id,  
};

firebase.initializeApp(firebaseConfig);

export default {
  data() {
    return {
      otp: ["", "", "", "", "", ""],
      otpDigits: [0, 1, 2, 3, 4, 5],
      ootp: null,
      seconds: 60,
      countdown : "1:00",
      resentOtp: false,
      recaptchaVerifier: null,
      recaptchaWidgetId: null,
      isRecaptchaRendered: false,
      otpsent: null,
      otpnotsent: null,
    };
  },
  computed: {
    myValue() {
      return this.$store.state.starttimer;
    },
  },
  watch: {
    myValue(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.startCountdown();
      }
    },
    resetCountdown(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.startCountdown();
      }
    },
  },
  methods: {
    destroyRecaptcha() {
      if (this.recaptchaVerifier) {
        this.recaptchaVerifier.clear();
        this.recaptchaVerifier = null;
        this.recaptchaWidgetId = null;
        this.isRecaptchaRendered = false;
      }
    },
    async firebase() {
      if (!this.isRecaptchaRendered) {
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container"
        );
        this.recaptchaVerifier
          .render()
          .then((widgetId) => {
            this.recaptchaWidgetId = widgetId;
          })
          .catch(() => {
            this.starttimer();
            this.$router.push({ name: "ootpPage" });
            this.otpnotsent = true;
          });

        this.isRecaptchaRendered = true;
      }
      this.recaptchaVerifier.verify().then(async () => {
        const number = this.$store.state.phone;
        const appVerifier = this.recaptchaVerifier;
        try {
          const confirmationResult = await firebase
            .auth()
            .signInWithPhoneNumber(number, appVerifier);
          this.$store.state.confirmationResult = confirmationResult;
          this.confirmationResult = confirmationResult;
          this.otpsent = true;  
        } catch (e) {
          this.starttimer();
          this.otpnotsent = true;
          this.$router.push({ name: "ootpPage" });
          this.destroyRecaptcha();
        }
      });
    },
    deleteAC() {
      var jotp = this.otp.join("");
      if (this.resentOtp && jotp) {
        jotp = this.$store.state.firebaseaccount;
      }
      const userID = this.$store.state.userId;
      const phoneNumber = this.$store.state.phone.phoneNumber;
      const userData = `${userID}:${phoneNumber}:${jotp}`;
      this.$store.dispatch("delete", userData);
    },
    starttimer() {
      this.$store.state.starttimer = true;
    },
    handleInput(index) {
      if (index < this.otp.length - 1 && this.otp[index].trim() !== "") {
        this.$refs[`otpInput${index + 1}`][0].focus();
      }
      if (
        index === this.otp.length - 1 &&
        this.otp.join("").length === this.otp.length
      ) {
        this.$store.state.starttimer = false;
        this.$emit("otp-entered", this.otp.join(""));
        const jotp = this.otp.join("");
        const object = {
          phoneNumber: this.$store.state.phone.phoneNumber,
          code: jotp,
        };
        const otp = this.otp.join("");
        if (this.resentOtp && otp) {
          this.$store.dispatch("confirmotpfirebase", otp);
        } else {
          this.$store.dispatch("confirmotp", object);
        }
      }
    },
    startCountdown() {
      this.interval = setInterval(() => {
        this.seconds--;

        this.countdown = this.seconds;

        if (this.seconds <= 0) {
          clearInterval(this.interval);
          this.$store.state.starttimer = false;
        }
      }, 1000);
    },
    submit() {
      const jotp = this.otp.join("");
      const object = {
        phoneNumber: this.$store.state.phone.phoneNumber,
        code: jotp,
      };
      this.$store.dispatch("confirmotp", object);
      this.otp = ["", "", "", "", "", ""];
    },
    clickIcon() {
      this.selectedOption1 = !this.selectedOption1;
    },
    gotnext() {
      this.$router.push({ name: "accountdelete" });
    },
    gotoprev() {
      this.$router.push({ name: "ratingpage" });
    },
    gotoprivacy() {
      this.$router.push({ name: "privacypolicy" });
    },
    gotoresend() {
      this.firebase();
      this.$store.state.accountntf = false;
      this.$store.state.accountexists = false;
      this.$store.state.accountexists = false;
      this.resentOtp = true;
      this.otpnotsent = null;
      this.otpsent = null;
    },
  },
};
</script>
<style scoped>
@media(min-width: 320px){

#id {
}
.deletedtextfield {
  margin-top: 5%;
  margin-left: 3%;
}

.deletedtext2 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.deletedtext1 {
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.deletedCanceling {
  position: absolute;
  margin-left: 72%;
  margin-top: 0.4%;
}

.deleted {
  display: flex;
  margin-top: 25%;
  border-radius: 10px;
  border: 1px rgba(29, 211, 176, 0.3) solid;
  width: 105%;
}

.column {
  flex: 1;
  padding: 20px;
}
.column2 {
  position: relative;
}


.inputtimer {
  margin-left: 2%;
}

.otp-input {
  display: flex;
  justify-content: space-between;
  width: 7px;
}

input {
  margin-top: 20%;
  width: 45px;
  height: 45px;
  text-align: center;
  font-size: 16px;
  margin-left: 5%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.7);
  border-radius: 5px;
  font-size: 32px;
}

input:last-child {
  margin-right: 0;
}

.reasonpage {
  padding-left: 5%;
  padding-right: 5%;
}

.inputelement {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  position: relative;
  font-size: 32px;
  border-radius: 10px;
  text-align: center;
}

.deletebtn {
  margin-top: 5%;
  margin-left: 48%;
}

.resettext {
  margin-top: 5%;
  color: #232735;
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  text-decoration: underline;
  word-wrap: break-word;
}

.star-image {
  margin-right: 30%;
}

.reviewtext {
  width: 100%;
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.canceling {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 80%;
}

.logo {
  margin-left: 25%;
  margin-top: 125%;
}
.navtext:hover {
  color: rgb(69, 211, 176);
}
}
@media(min-width: 375px){

#id {
}
.deletedtextfield {
  margin-top: 5%;
  margin-left: 3%;
}

.deletedtext2 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.deletedtext1 {
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.deletedCanceling {
  position: absolute;
  margin-left: 72%;
  margin-top: 0.4%;
}

.deleted {
  display: flex;
  margin-top: 25%;
  border-radius: 10px;
  border: 1px rgba(29, 211, 176, 0.3) solid;
  width: 105%;
}

.column {
  flex: 1;
  padding: 20px;
}
.column2 {
  position: relative;
}


.inputtimer {
  margin-left: 2%;
}

.otp-input {
  display: flex;
  justify-content: space-between;
  width: 7px;
}

input {
  margin-top: 20%;
  width: 55px;
  height: 64px;
  text-align: center;
  font-size: 16px;
  margin-left: 5%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.7);
  border-radius: 5px;
  font-size: 32px;
}

input:last-child {
  margin-right: 0;
}

.reasonpage {
  padding-left: 5%;
  padding-right: 5%;
}

.inputelement {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  position: relative;
  font-size: 32px;
  border-radius: 10px;
  text-align: center;
}

.deletebtn {
  margin-top: 5%;
  margin-left: 48%;
}

.resettext {
  margin-top: 5%;
  color: #232735;
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  text-decoration: underline;
  word-wrap: break-word;
}

.star-image {
  margin-right: 30%;
}

.reviewtext {
  width: 100%;
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.canceling {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 80%;
}

.logo {
  margin-left: 25%;
  margin-top: 95%;
}
.navtext:hover {
  color: rgb(69, 211, 176);
}
}
@media(min-width: 425px){

#id {
}
.deletedtextfield {
  margin-top: 5%;
  margin-left: 3%;
}

.deletedtext2 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.deletedtext1 {
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.deletedCanceling {
  position: absolute;
  margin-left: 72%;
  margin-top: 0.4%;
}

.deleted {
  display: flex;
  margin-top: 25%;
  border-radius: 10px;
  border: 1px rgba(29, 211, 176, 0.3) solid;
  width: 100%;
}

.column {
  flex: 1;
  padding: 20px;
}
.column2 {
  position: relative;
}


.inputtimer {
  margin-left: 2%;
}

.otp-input {
  display: flex;
  justify-content: space-between;
  width: 7px;
}

input {
  margin-top: 20%;
  width: 63px;
  height: 64px;
  text-align: center;
  font-size: 16px;
  margin-left: 5%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.7);
  border-radius: 5px;
  font-size: 32px;
}

input:last-child {
  margin-right: 0;
}

.reasonpage {
  padding-left: 5%;
  padding-right: 5%;
}

.inputelement {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  position: relative;
  font-size: 32px;
  border-radius: 10px;
  text-align: center;
}

.deletebtn {
  margin-top: 5%;
  margin-left: 48%;
}

.resettext {
  margin-top: 5%;
  color: #232735;
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  text-decoration: underline;
  word-wrap: break-word;
}

.star-image {
  margin-right: 30%;
}

.reviewtext {
  width: 100%;
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.canceling {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 80%;
}

.logo {
  margin-left: 25%;
  margin-top: 75%;
}
.navtext:hover {
  color: rgb(69, 211, 176);
}
}

@media(min-width: 768px){

/* .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
} */

#id {
}
.deletedtextfield {
  margin-top: 5%;
  margin-left: 3%;
}

.deletedtext2 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.deletedtext1 {
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.deletedCanceling {
  position: absolute;
  margin-left: 60%;
  margin-top: 0.4%;
}

.deleted {
  display: flex;
  margin-top: 25%;
  border-radius: 10px;
  border: 1px rgba(29, 211, 176, 0.3) solid;
  width: 80%;
}

.column {
  flex: 1;
  padding: 20px;
}
.column2 {
  position: relative;
}


.inputtimer {
  margin-left: 2%;
}

.otp-input {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

input {
  margin-top: 20%;
  width: 66px;
  height: 64px;
  text-align: center;
  font-size: 16px;
  margin-left: 5%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.7);
  border-radius: 5px;
  font-size: 32px;
}

input:last-child {
  margin-right: 0;
}

.reasonpage {
  padding-left: 5%;
  padding-right: 5%;
}

.inputelement {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  position: relative;
  font-size: 32px;
  border-radius: 10px;
  text-align: center;
}

.deletebtn {
  margin-top: 5%;
  margin-left: 48%;
}

.resettext {
  margin-top: 5%;
  color: #232735;
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  text-decoration: underline;
  word-wrap: break-word;
}

.star-image {
  margin-right: 30%;
}

.reviewtext {
  width: 100%;
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.canceling {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 80%;
}

.logo {
  margin-left: 25%;
  margin-top: 35%;
}
.navtext:hover {
  color: rgb(69, 211, 176);
}
}
@media(min-width: 1024px){

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

#id {
}
.deletedtextfield {
  margin-top: 5%;
  margin-left: 3%;
}

.deletedtext2 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.deletedtext1 {
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.deletedCanceling {
  position: absolute;
  margin-left: 23%;
  margin-top: 0.4%;
}

.deleted {
  display: flex;
  margin-top: 25%;
  border-radius: 10px;
  border: 1px rgba(29, 211, 176, 0.3) solid;
  width: 80%;
}

.column {
  flex: 1;
  padding: 20px;
}
.clomun1 {
  background: url("../assets/img/deleteAc/final.png");
}
.column2 {
  position: relative;
}


.inputtimer {
  margin-left: 2%;
}

.otp-input {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

input {
  margin-top: 20%;
  width: 66px;
  height: 64px;
  text-align: center;
  font-size: 16px;
  margin-left: 5%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.7);
  border-radius: 5px;
  font-size: 32px;
}

input:last-child {
  margin-right: 0;
}

.reasonpage {
  padding-left: 5%;
  padding-right: 5%;
}

.inputelement {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  position: relative;
  font-size: 32px;
  border-radius: 10px;
  text-align: center;
}

.deletebtn {
  margin-top: 5%;
  margin-left: 48%;
}

.resettext {
  margin-top: 5%;
  color: #232735;
  font-size: 16px;
  font-family: Manrope;
  font-weight: 500;
  text-decoration: underline;
  word-wrap: break-word;
}

.star-image {
  margin-right: 30%;
}

.reviewtext {
  width: 100%;
  color: black;
  font-size: 20px;
  font-family: Manrope;
  font-weight: 500;
  word-wrap: break-word;
}

.canceling {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 80%;
}

.logo {
  margin-left: 25%;
  margin-top: 100%;
}
.navtext:hover {
  color: rgb(69, 211, 176);
}
}
@media(min-width: 2560px){

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  #id {
  }
  .deletedtextfield {
    margin-top: 5%;
    margin-left: 3%;
  }

  .deletedtext2 {
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .deletedtext1 {
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .deletedCanceling {
    position: absolute;
    margin-left: 17%;
    margin-top: 0.4%;
  }

  .deleted {
    display: flex;
    margin-top: 25%;
    border-radius: 10px;
    border: 1px rgba(29, 211, 176, 0.3) solid;
    width: 80%;
  }

  .column {
    flex: 1;
    padding: 20px;
  }
  .clomun1 {
    background: url("../assets/img/deleteAc/final.png");
  }
  .column2 {
    position: relative;
  }


  .inputtimer {
    margin-left: 2%;
  }

  .otp-input {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }

  input {
    margin-top: 20%;
    width: 66px;
    height: 64px;
    text-align: center;
    font-size: 16px;
    margin-left: 5%;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.7);
    border-radius: 5px;
    font-size: 32px;
  }

  input:last-child {
    margin-right: 0;
  }

  .reasonpage {
    padding-left: 5%;
    padding-right: 5%;
  }

  .inputelement {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    position: relative;
    font-size: 32px;
    border-radius: 10px;
    text-align: center;
  }

  .deletebtn {
    margin-top: 5%;
    margin-left: 48%;
  }

  .resettext {
    margin-top: 5%;
    color: #232735;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 500;
    text-decoration: underline;
    word-wrap: break-word;
  }

  .star-image {
    margin-right: 30%;
  }

  .reviewtext {
    width: 100%;
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .canceling {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 80%;
  }

  .logo {
    margin-left: 25%;
    margin-top: 100%;
  }
  .navtext:hover {
    color: rgb(69, 211, 176);
  }
}
</style>
