<template>
  <body>
    <div>
      <!-- Page loading spinner -->
      <div class="page-loading">
        <div class="page-loading-inner">
          <div class="page-spinner"></div>
          <span>Loading...</span>
        </div>
      </div>
      <!-- Page wrapper for sticky footer -->
      <!-- Wraps everything except footer to push footer to the bottom of the page if there is little content -->
      <main class="page-wrapper">
        <!-- Navbar -->
        <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page -->
        <header
          class="header navbar navbar-expand-lg position-absolute navbar-sticky"
        >
          <div class="container px-3">
            <a href="/" class="navbar-brand pe-3">
              <img src="assets/img/logo.svg" alt="Tibalink" />
            </a>
            <div id="navbarNav" class="offcanvas offcanvas-end">
              <div class="offcanvas-body">
                <ul class="navbar-nav me-auto mt-2 mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a href="/#features" class="nav-link">Features</a>
                  </li>
                  <li class="nav-item">
                    <a href="/" class="nav-link">Home</a>
                  </li>
                  <li class="nav-item">
                    <a href="/#about" class="nav-link">About</a>
                  </li>
                  <!-- <li class="nav-item">
                      <a @click="features" class="nav-link">Privacy Policy</a>
                    </li> -->
                </ul>
              </div>
            </div>
            <button
              type="button"
              class="navbar-toggler shadow-color"
              data-bs-toggle="offcanvas"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a
              href="index.html#section-cta"
              class="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex"
            >
              Get Early Access
            </a>
          </div>
        </header>
        <!-- FAQ (Accordion) -->
        <section class="container py-5 mb-2 mt-5 my-md-4 my-lg-5">
          <div
            id="bg-pattern"
            class="position-relative rounded-3 overflow-hidden px-3 px-sm-4 px-md-0 py-5"
          >
            <div
              class="row justify-content-center position-relative zindex-2 py-lg-4"
            >
              <div class="col-xl-8 col-lg-9 col-md-10 py-2">
                <h2 class="h1 text-dark text-center mt-n2 mt-lg-0 mb-4 mb-lg-5">
                  Terms of Service
                </h2>
                <div class="accordion" id="faq">
                  <!-- Item -->
                  <div>
                    <AccordionItem
                      v-for="(item, index) in accordionItems"
                      :key="index"
                      :item="item"
                      :is-expanded="isOpen[index]"
                      @toggleAccordion="toggleAccordion(index)"
                    >
                    </AccordionItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <!-- Footer -->
      <footer
        class="footer pt-5 pb-4 pb-lg-5"
        style="background-color: rgb(243, 246, 255)"
      >
        <div class="container pt-lg-4">
          <div class="row pb-5">
            <div class="col-lg-4 col-md-6">
              <div class="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
                <img src="../assets/img/logo.svg" alt="Silicon" />
              </div>
              <p class="fs-sm pb-lg-3 mb-4">
                TibaLink is an initiative for doctors, by doctors, aimed at
                improving the speed and efficiency of communication in
                healthcare.
              </p>
              <form
                class="subscription-form needs-validation validate"
                ref="form"
                novalidate
                style="max-width: 420px"
              >
                <label for="subscr-email" class="form-label"
                  >Subscribe to our newsletter</label
                >
                <div class="input-group">
                  <input
                    type="email"
                    id="subscr-email"
                    class="form-control rounded-start ps-5"
                    name="EMAIL"
                    placeholder="Your email"
                    required
                  />
                  <i
                    class="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"
                  ></i>
                  <div
                    class="invalid-tooltip position-absolute top-100 start-0"
                  >
                    Please provide a valid email address.
                  </div>
                  <button
                    type="submit"
                    class="btn btn-hov btn-primary"
                    name="subscribe"
                    @click="subscribeToMailchimp"
                  >
                    Subscribe*
                  </button>
                </div>
                <div class="form-text pt-1 mt-4">
                  *Subscribe to our newsletter to receive updates and new
                  product info.
                </div>
                <div
                  style="position: absolute; left: -5000px"
                  aria-hidden="true"
                >
                  <input
                    class="subscription-form-antispam"
                    type="text"
                    name="b_4484f072bf6c3c4fcc5bd0807_b165551d9d"
                    tabindex="-1"
                  />
                </div>
                <div class="subscription-status"></div>
              </form>
            </div>
            <div
              class="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0"
            >
              <div id="footer-links" class="row">
                <div class="col-lg-4">
                  <h6 class="mb-2">
                    <a
                      href="#useful-links"
                      class="d-block text-dark dropdown-toggle d-lg-none py-2"
                      data-bs-toggle="collapse"
                      >Useful Links</a
                    >
                  </h6>
                  <div id="useful-links" class="collapse d-lg-block">
                    <ul class="nav flex-column pb-lg-1 mb-lg-3">
                      <li class="nav-item">
                        <a
                          href="/"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Home</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="/#about"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >About</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="/#features"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Features</a
                        >
                      </li>
                    </ul>
                    <ul class="nav flex-column mb-2 mb-lg-0">
                      <li class="nav-item">
                        <a
                          @click="termsofservice"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Terms of Service</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href=""
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          @click="privacypolicy"
                          >Privacy Policy</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-3">
                  <h6 class="mb-2">
                    <a
                      href="#social-links"
                      class="d-block text-dark dropdown-toggle d-lg-none py-2"
                      data-bs-toggle="collapse"
                      >Socials</a
                    >
                  </h6>
                  <div
                    id="social-links"
                    class="collapse d-lg-block"
                    data-bs-parent="#footer-links"
                  >
                    <ul class="nav flex-column mb-2 mb-lg-0">
                      <li class="nav-item">
                        <a
                          href="https://www.facebook.com/profile.php?id=100093048056096"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Facebook</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="https://www.linkedin.com/company/tibalink/"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >LinkedIn</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="https://twitter.com/Tibalink_"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Twitter</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="https://www.instagram.com/tibalink_/"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Instagram</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-5 pt-2 pt-lg-0">
                  <h6 class="mb-2">Contact Us</h6>
                  <a href="mailto:tibalink@qhala.com" class="fw-medium"
                    >tibalink@qhala.com</a
                  >
                </div>
              </div>
            </div>
          </div>
          <p
            class="nav d-block fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0"
          >
            Made in Africa with ❤️ by Qhala.
          </p>
        </div>
      </footer>
    </div>
  </body>
</template>

<script>
import AccordionItem from "../components/AccordionItem.vue";

export default {
  components: {
    AccordionItem,
  },
  data() {
    return {
      accordionItems: [
        // Define your accordion items here
        {
          title: "1.0   Introduction",
          content: `<p>Welcome to Tiblink, a cutting-edge health technology solution provider that offers a comprehensive health management 
                            information system catering to the global healthcare community. Our Electronic Medical Records Platform, known as Tibalink, 
                                is designed to empower healthcare facilities and professionals with better patient outcomes by enabling efficient communication 
                                among healthcare teams and professionals (hereinafter referred to as the "Service").
                                <br/>Qhala LTD  is the entity behind Tibalink, and these Terms of Service govern your use of the Service, 
                                creating a legally binding contract between you and Tibalink. It is essential to read these Terms of Service 
                                carefully before accessing or using the Service. Additionally, you can review our Privacy Notice
                                 <a <a href="/privacypolicy">[PrivacyPolicy]</a>  for information on how we handle personal data.
                                </p>`,
        },
        {
          title: "2.0   Nature and Applicability of Terms",
          content: `<p>These Terms of Service, subject to periodic amendments at our sole discretion, regulate the use of the Service. 
                        By accessing or using the content, services, and materials on our website https:Tibalink.com and related applications,
                        you acknowledge having read, understood, accepted, and agreed to be bound by these Terms.
                        <p>Each use of the Service, whether registered or not, implies acceptance of these Terms of Service. 
                            If you disagree with any provision, refrain from accessing or using our Website or the Service.<p/>
                            <p>By installing the Tibalink app you consent your agreement with these Terms of Service<p/> </p>`,
        },
        // A,
        {
          title: "3.0. Privacy Notice",
          content: `<p>Qhala LTD  is committed to managing personal data in accordance with global industry best practices. We encourage you to read our Privacy Notice
                        <a href="/privacypolicy">[PrivacyPolicy]</a> to understand how we handle personal data when you access or use our Website or Service.</p>`,
        },
        {
          title: "4.0.  Eligibility for Our Service",
          content: `<p>By utilizing our Service, you represent and warrant that you, or your authorized representative, have reached the
                         age of majority in your jurisdiction and are legally capable of entering binding contracts, including these Terms of Service. 
                         If using the Service on behalf of another party, you affirm that you have the authority to act on their behalf and bind them to these Terms.</p>
                         <p>Access to our Services is granted only to users who have executed a separate written services agreement with Qhala LTD</p>`,
        },
        {
          title: "5.0 . Use of Information",
          content: `<p>Tibalink provides an Electronic Medical Record (EMR) technology platform, allowing healthcare entities 
                        and professionals to manage health-related information digitally. This includes data related to a patient's physical, mental, 
                        and medical condition, health, and treatment.</p>
                        <p> To use our Platform, users must register by providing certain personal information. Users agree to provide accurate and
                             current information, and to update it promptly if changes occur. Acceptance of these Terms, registration, and agreement 
                             to our Privacy Notice are prerequisites for accessing the Service.</p>
                        <p>Users are solely responsible for the use of the Service by their employees or agents and agree to hold Qhala LTD  harmless 
                            for any related liabilities.</p>`,
        },
        {
          title: "6.0 . Intellectual Property",
          content: ` <p><strong>Our Content: </strong> <br/> All content and materials provided through our Services, including text, images, and software, are the exclusive
                         property of Qhala LTD.  Users may only use this content within the scope of our Services, without modifying, selling, or reproducing it without our express
                          written consent.</p>
                          <p><strong>Our Partners’ Intellectual Property: </strong> <br/> Third-party intellectual property displayed through our Services, such as names, images,
                             documents and logos, remains the property of their respective owners. Users are prohibited from copying, modifying, or reproducing this content without 
                             the explicit permission of the owners.</p>
                          `,
        },
        {
          title: "7.0 . Screening Users",
          content: `<p>Qhala LTD  retains the right to screen users and monitor communication and information transmitted through the Service.
                         Verification of user identity and credentials may be conducted at our discretion. Users agree that access to the Service may be terminated
                          if qualifications or credentials cannot be verified.</p>`,
        },
        {
          title: "8.0 . Account Management and Security",
          content: `<p><strong>Keep Your Password Secure</strong> <br/>
                        Users are responsible for safeguarding their account credentials. Unauthorized access to an account must be reported to Qhala LTD immediately.
                         Users are liable for any activity on their account, including unauthorized use.</p>
                         <p><strong>Keep Your Details Accurate:</strong> <br/>
                            Users must keep their contact information current. Qhala LTD may send notices to registered email addresses or mobile phones.
                            Users agree to promptly update their information in case of changes.</p>
                        <p><strong>Change of Information:</strong> <br/>
                            Users can notify Qhala LTD in writing of any changes to their registration information.</p> `,
        },
        {
          title: "9.0 . Account Deletion",
          content: `<p>Users can delete their account at any time by clicking on the provided "Delete Account" link within the account management settings in 
                        the app or on the Tibalink website using this link <a href="/privacypolicy">[PrivacyPolicy]</a> 
                        This action is irreversible, and all associated data may be permanently removed from our system.</p>`,
        },
        {
          title: "10.0 Breach Notification Policy",
          content: `<p>If a user suspects or learns of a security breach or compromise, they must notify Qhala LTD immediately. Failure to comply with 
                        breach notification policies may result in legal consequences, and users agree to hold Qhala LTD free from liability in such cases.</p>`,
        },
        {
          title: "11.0  Links to Third-Party Sites or Applications",
          content: `<p>Our Services may contain Third-Party Content or connect with Third Party Services. Qhala LTD does not control or endorse these entities 
                        and is not liable for any related damages or losses. Users are encouraged to review the terms of service and privacy notices associated
                         with Third-Party Content or Third Party Services.</p> `,
        },
        {
          title: "12.0 Reliance on Information Posted",
          content: `<p>Information on our Website is provided for general informational purposes. We do not guarantee the accuracy or completeness of this 
                            information. Users rely on such information at their own risk.</p>`,
        },
        {
          title: "13.0 Representations and Warranties",
          content: `<p>Users represent and warrant to Qhala LTD that they have the authority to enter into this agreement, will comply with applicable 
                        laws, and will not abuse the Service or generate false feedback. Users must provide accurate information and accept and agree to these Terms.</p>`,
        },
        {
          title: "14. Responsibilities ",
          content: `<p>By agreeing to these terms, users accept responsibilities, including adhering to charges for services, managing account security, 
                        refraining from prohibited activities, and promptly updating account information.</p>`,
        },
        {
          title: "15  Termination of Access ",
          content: `<p>Qhala LTD reserves the right to terminate access to the Service at any time if qualifications or credentials cannot be verified, 
                        without liability.</p> `,
        },
        {
          title: "16.0   Contact Information ",
          content: `<p>For any inquiries or notifications related to these Terms of Service, please contact us at tibalink@qhala.com.</p> `,
        },
      ],
      isOpen: [],
      email: "",
      formActionUrl:
        "https://tibalink.us13.list-manage.com/subscribe/post?u=4484f072bf6c3c4fcc5bd0807&id=b165551d9d&f_id=009537e2f0",
      responseHtml: "",
      isCollapsed: true,
    };
  },
  created() {
    // Initialize isOpen based on the number of accordion items
    this.isOpen = new Array(this.accordionItems.length).fill(false);
  },
  methods: {
    termsofservice() {
      this.$router.push({ name: "termsofservice" });
    },
    privacypolicy() {
      this.$router.push({ name: "privacypolicy" });
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleAccordion(index) {
      // Toggle the state of the clicked item and close others
      this.isOpen = this.isOpen.map((state, i) =>
        i === index ? !state : false
      );
    },
    home() {
      this.$router.push({ name: "home" });
    },
    features() {
      this.$router.push({ name: "home", params: { id: "#about" } });
    },
    about() {
      this.$router.push({ name: "home", params: { id: "#features" } });
    },
    deleteAcount() {
      this.$router.push({ name: "deleteaccount" });
    },
    submitForm() {
      console.log("submitting   ");
      fetch(this.$refs.form.action, {
        method: "POST",
        body: new FormData(this.$refs.form),
      })
        .then((response) => response.text()) // Read the response as HTML
        .then((htmlData) => {
          // Set the response HTML to your component's data property
          this.responseHtml = htmlData;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.collapse:not(.show) {
  display: none;
}

.accordion-collapse {
  border-bottom-right-radius: var(--si-accordion-border-radius);
  border-bottom-left-radius: var(--si-accordion-border-radius);
}

.nav-link {
  color: #33354d;
}

.nav-link:hover {
  color: #1dd3b0;
  /* Your hover color here */
}

.page-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.4s 0.2s ease-in-out;
  transition: all 0.4s 0.2s ease-in-out;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
}

.dark-mode .page-loading {
  background-color: #0b0f19;
}

.page-loading.active {
  opacity: 1;
  visibility: visible;
}

.page-loading-inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.page-loading.active > .page-loading-inner {
  opacity: 1;
}

.page-loading-inner > span {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  color: #9397ad;
}

.dark-mode .page-loading-inner > span {
  color: #fff;
  opacity: 0.6;
}

.page-spinner {
  display: inline-block;
  width: 2.75rem;
  height: 2.75rem;
  margin-bottom: 0.75rem;
  vertical-align: text-bottom;
  border: 0.15em solid #b4b7c9;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner 0.75s linear infinite;
  animation: spinner 0.75s linear infinite;
}

.dark-mode .page-spinner {
  border-color: rgba(255, 255, 255, 0.4);
  border-right-color: transparent;
}

@-webkit-keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.card-color {
  background-color: #1abe9e !important;
}

.border-color {
  border-color: #1abe9e !important;
}

.shadow-color {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(69, 178, 176, 0.9) !important;
}

.card-color {
  background-color: #1abe9e !important;
}

.border-color {
  border-color: #1abe9e !important;
}

.shadow-color {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(69, 178, 176, 0.9) !important;
}

.btn:hover {
  background-color: #006466 !important;
  border: solid 1px #006466 !important;
  color: #ffff;
}

.text-color-blue {
  color: #006466 !important;
}

.text-color-blue {
  color: #006466 !important;
}

.text-color-blue-light {
  color: #1dd3b0 !important;
}
</style>
