<template>
  <router-view />
  <!-- <router-link to="/">Home</router-link> |
  <router-link to="/privacypolicy">about</router-link> -->
</template>

<style>
#app {
  height:946;
  width:1081
}
/* nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
