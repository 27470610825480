import { createStore } from "vuex";
import router from "../router";


import { tibalinkapi, encrypt } from "./utility";

export default createStore({
  actions: {
    async reasons(context, data) {
      try {
        const response = await tibalinkapi.post("profile/exit", data);
        if (response.status === 200) {
          console.log("user-deleted");
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async reason(context, data) {
      this.state.reason = data.data;
    },
    async review(context, data) {
      this.state.review = data.data;
    },
    async checkUser(context, data) {
      this.state.phone = data.phoneNumber;
      try {
        this.state.loading = true;
        const response = await tibalinkapi.post("otp/contact-exist", data);
        if (response.status === 200) {
          this.state.userId = response.data.data.id;
          this.state.loading = false;
          this.state.accountexists = true;
          this.state.accountntf = false;
        }
      } catch (error) {
        console.error(error);
        this.state.accountntf = true;
        this.state.accountexists = false;
        this.state.loading = false;
      }
    },
    async sendootp(context, data) {
      try {
        this.state.phone = data;
        this.state.loading = true;
        const response = await tibalinkapi.post("/otp/request", data);
        if (response.status === 200) {
          this.state.accountFound = true;
          this.state.starttimer = true;
          this.state.loading = false;
        }
      } catch (error) {
        this.state.loading = false;
        if (error.response.data.code === 400) {
          this.state.loading = false;
          this.state.otptimeout = true;
          this.state.otpsent = false;
          this.state.starttimer = true;
          router.push({ name: "accountdelete" });
        }
      }
    },
    async delete(context, data) {
      try {
        const encryptedData = encrypt(data);
        const response = await tibalinkapi.delete("profile/web", {
          data: { cipher: encryptedData },
        });
        if (response.status === 200)  {
          this.state.deleted = true;
          await tibalinkapi.post("profile/exit", {
            userId: this.state.userId,
            message: this.state.reason || "nan",
            rating: this.state.review || "nan",
          });
        }
      } catch {
        this.state.accountNotDelted = true;
      }
    },
    async confirmotp(context, data) {
      try {
        this.state.delloading = true;
        const response = await tibalinkapi.post("otp/verify", data);
        if (response.status === 200) {
          this.state.otpConfirmed = true;
          this.state.delloading = false;
          this.state.invalidCode = false;
        }
      } catch (error) {
        this.state.delloading = false;
        if (error.response.data.code === 400) {
          this.state.otpConfirmed = false;
          this.state.invalidCode = true;
          this.state.otpConfirmed = false;
        }
      }
    },
    async confirmotpfirebase(context, data) {
      console.log("otp",data);
        const code = parseInt(data, 10);
        console.log(code);
        this.state.delloading = true;
        this.state.confirmationResult.confirm(code).then(async (result) => {
          // User signed in successfully.
        if (result.user.multiFactor.user.accessToken)
        {
          this.state.otpConfirmed = true;
          this.state.delloading = false;
          this.state.invalidCode = false;
          const data = {
            phoneNumber: this.state.phone.phoneNumber,
            idToken: result.user.multiFactor.user.accessToken,
          };
          await tibalinkapi.post("otp/firebase", data).then((response) => {
            console.log(response);
          }).catch(() => {router.push({ name: "accountdelete" });});
        }

        this.state.firebaseaccount = result.user.multiFactor.user.accessToken;          

        console.log("fromfirebase-confirm",this.state.firebaseaccount);
        // ...
      }).catch(() => {
        this.state.delloading = false;
        this.state.otpConfirmed = false;
        this.state.invalidCode = true;
        this.state.otpConfirmed = false;
        router.push({ name: "accountdelete" });
      });
    },
  },
  state: {
    firebaseaccount:null,
    firebaseOtpsent: null,
    firebaseotpNotSent: null,
    confirmationResult:null,
    accountexists:null,
    accountntf:null,
    accountDeleted: null,
    accountNotDelted:null,
    reason: null,
    review: null,
    invalidCode: null,
    userId: null,
    otpConfirmed: null,
    otpsent: null,
    otptimeout: null,
    phone: null,
    ootp: null,
    correct: false,
    loading: false,
    delloading: false,
    failed: false,
    starttimer: false,
    snackbar: {
      show: false,
      text: "",
      color: "",
    },
  },
});
