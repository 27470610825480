<template>
  <body>
    <div>
      <!-- Page loading spinner -->
      <div class="page-loading">
        <div class="page-loading-inner">
          <div class="page-spinner"></div>
          <span>Loading...</span>
        </div>
      </div>
      <!-- Page wrapper for sticky footer -->
      <!-- Wraps everything except footer to push footer to the bottom of the page if there is little content -->
      <main class="page-wrapper">
        <!-- Navbar -->
        <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page -->
        <header
          class="header navbar navbar-expand-lg position-absolute navbar-sticky"
        >
          <div class="container px-3">
            <a href="/" class="navbar-brand pe-3">
              <img src="assets/img/logo.svg" alt="Tibalink" />
            </a>
            <div id="navbarNav" class="offcanvas offcanvas-end">
              <div class="offcanvas-body">
                <ul class="navbar-nav me-auto mt-2 mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a href="/#features" class="nav-link">Features</a>
                  </li>
                  <li class="nav-item">
                    <a href="/" class="nav-link">Home</a>
                  </li>
                  <li class="nav-item">
                    <a href="/#about" class="nav-link">About</a>
                  </li>
                  <!-- <li class="nav-item">
                    <a @click="features" class="nav-link">Privacy Policy</a>
                  </li> -->
                </ul>
              </div>
            </div>
            <button
              type="button"
              class="navbar-toggler shadow-color"
              data-bs-toggle="offcanvas"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a
              href="index.html#section-cta"
              class="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex"
            >
              Get Early Access
            </a>
          </div>
        </header>
        <!-- FAQ (Accordion) -->
        <section class="container py-5 mb-2 mt-5 my-md-4 my-lg-5">
          <div
            id="bg-pattern"
            class="position-relative rounded-3 overflow-hidden px-3 px-sm-4 px-md-0 py-5"
          >
            <div
              class="row justify-content-center position-relative zindex-2 py-lg-4"
            >
              <div class="col-xl-8 col-lg-9 col-md-10 py-2">
                <h2 class="h1 text-dark text-center mt-n2 mt-lg-0 mb-4 mb-lg-5">
                  Privacy Policy
                </h2>
                <div class="accordion" id="faq">
                  <!-- Item -->
                  <div>
                    <AccordionItem
                      v-for="(item, index) in accordionItems"
                      :key="index"
                      :item="item"
                      :is-expanded="isOpen[index]"
                      @toggleAccordion="toggleAccordion(index)"
                    ></AccordionItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <!-- Footer -->
      <footer
        class="footer pt-5 pb-4 pb-lg-5"
        style="background-color: rgb(243, 246, 255)"
      >
        <div class="container pt-lg-4">
          <div class="row pb-5">
            <div class="col-lg-4 col-md-6">
              <div class="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
                <img src="../assets/img/logo.svg" alt="Silicon" />
              </div>
              <p class="fs-sm pb-lg-3 mb-4">
                TibaLink is an initiative for doctors, by doctors, aimed at
                improving the speed and efficiency of communication in
                healthcare.
              </p>
              <form
                class="subscription-form needs-validation validate"
                ref="form"
                novalidate
                style="max-width: 420px"
              >
                <label for="subscr-email" class="form-label"
                  >Subscribe to our newsletter</label
                >
                <div class="input-group">
                  <input
                    type="email"
                    id="subscr-email"
                    class="form-control rounded-start ps-5"
                    name="EMAIL"
                    placeholder="Your email"
                    required
                  />
                  <i
                    class="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"
                  ></i>
                  <div
                    class="invalid-tooltip position-absolute top-100 start-0"
                  >
                    Please provide a valid email address.
                  </div>
                  <button
                    type="submit"
                    class="btn btn-hov btn-primary"
                    name="subscribe"
                    @click="subscribeToMailchimp"
                  >
                    Subscribe*
                  </button>
                </div>
                <div class="form-text pt-1 mt-4">
                  *Subscribe to our newsletter to receive updates and new
                  product info.
                </div>
                <div
                  style="position: absolute; left: -5000px"
                  aria-hidden="true"
                >
                  <input
                    class="subscription-form-antispam"
                    type="text"
                    name="b_4484f072bf6c3c4fcc5bd0807_b165551d9d"
                    tabindex="-1"
                  />
                </div>
                <div class="subscription-status"></div>
              </form>
            </div>
            <div
              class="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0"
            >
              <div id="footer-links" class="row">
                <div class="col-lg-4">
                  <h6 class="mb-2">
                    <a
                      href="#useful-links"
                      class="d-block text-dark dropdown-toggle d-lg-none py-2"
                      data-bs-toggle="collapse"
                      >Useful Links</a
                    >
                  </h6>
                  <div id="useful-links" class="collapse d-lg-block">
                    <ul class="nav flex-column pb-lg-1 mb-lg-3">
                      <li class="nav-item">
                        <a
                          href="/"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Home</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="/#about"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >About</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="/#features"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Features</a
                        >
                      </li>
                    </ul>
                    <ul class="nav flex-column mb-2 mb-lg-0">
                      <li class="nav-item">
                        <a
                          @click="termsofservice"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Terms of Service</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href=""
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          @click="privacypolicy"
                          >Privacy Policy</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-3">
                  <h6 class="mb-2">
                    <a
                      href="#social-links"
                      class="d-block text-dark dropdown-toggle d-lg-none py-2"
                      data-bs-toggle="collapse"
                      >Socials</a
                    >
                  </h6>
                  <div
                    id="social-links"
                    class="collapse d-lg-block"
                    data-bs-parent="#footer-links"
                  >
                    <ul class="nav flex-column mb-2 mb-lg-0">
                      <li class="nav-item">
                        <a
                          href="https://www.facebook.com/profile.php?id=100093048056096"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Facebook</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="https://www.linkedin.com/company/tibalink/"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >LinkedIn</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="https://twitter.com/Tibalink_"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Twitter</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          href="https://www.instagram.com/tibalink_/"
                          class="nav-link d-inline-block px-0 pt-1 pb-2"
                          >Instagram</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-5 pt-2 pt-lg-0">
                  <h6 class="mb-2">Contact Us</h6>
                  <a href="mailto:tibalink@qhala.com" class="fw-medium"
                    >tibalink@qhala.com</a
                  >
                </div>
              </div>
            </div>
          </div>
          <p
            class="nav d-block fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0"
          >
            Made in Africa with ❤️ by Qhala.
          </p>
        </div>
      </footer>
    </div>
  </body>
</template>

<script>
import AccordionItem from "../components/AccordionItem.vue";

export default {
  components: {
    AccordionItem,
  },
  data() {
    return {
      accordionItems: [
        // Define your accordion items here
        {
          title: "1.0   Introduction",
          content: `<p>This Privacy Policy describes how Tibalink protects the personal data it processes, why and how <strong>"personal data"</strong> is collected, used, stored, and shared and how you can exercise your rights in relation to the processing of your <strong>"personal data"</strong>.</p>`,
        },
        {
          title: "2.0   Definitions",
          content: `<p><strong>"Tibalink" or "We"</strong> refers to Tibalink, including its successors in title and assigns, its affiliates, and subsidiaries.</p>

<p><strong>"Personal data"</strong> refers to any information related to an identified or identifiable natural person.</p>

<p><strong>"Sensitive personal data"</strong> includes data revealing a person's race, health status, ethnic social origin, conscience, belief, genetic data, biometric data, property details, marital status, family details (including names of children, parents, spouse or spouses), sex, or sexual orientation of the data subject.</p>

<p><strong>"Processing"</strong> encompasses any operation or set of operations performed on personal data, whether automated or not.</p>

<p><strong>"You"</strong> pertains to individuals, end users, potential employees, and third-party service providers.</p>`,
        },
        // A,
        {
          title: "3.0. Information Collected",
          content: `<p>We collect the following types of data:</p>

<p><strong>Personal Information:</strong></p>
<ul>
  <li><strong>Name:</strong> We collect your name to address you personally and facilitate account management.</li>
  <li><strong>Email Address:</strong> Your email address is used for communication, account verification, and notifications.</li>
  <li><strong>User IDs:</strong> We gather user IDs (e.g., account numbers, account names) for access to our services.</li>
  <li><strong>Phone Number:</strong> Your phone number may be collected for communication and account verification.</li>
  <li><strong>Health Information:</strong> Data related to your health, such as medical records and symptoms, is collected to provide health-related services and support.</li>
</ul>

<p><strong>Emails:</strong> We may ask you for your email information. We collect this information for account verification, recovery, and being able to keep you updated on new features and offers that will be available on the platform.</p>

<p><strong>SMS:</strong> During registration and onboarding on to the platform, we may send you a one-time password (OTP) to verify the mobile number used in registration. We do not read other messages/SMS on the user’s phone.</p>

<p><strong>Photos and Videos:</strong> A photo and/or video may be shared by a medical physician on the Tibalink app for the purposes of seeking information or advice from other medical colleagues. We have taken measures to ensure that any video or photo shared on the platform cannot be shared outside of the platform.</p>

<p><strong>Voice or Sound Recordings:</strong> A voice note or sound recording may be shared by a medical physician on the Tibalink app for the purposes of seeking information or advice from other medical colleagues. We have taken measures to ensure that any voice note or sound recordings shared on the platform cannot be shared outside of the platform.</p>

<p><strong>Files and Documents:</strong> We collect user files or documents, including file names, for storage and document management purposes.</p>

<p><strong>Calendar Events:</strong> Information from your calendar, including event details, notes, and attendee lists, is collected to facilitate event scheduling and reminders.</p>

<p><strong>Contacts:</strong> Data concerning your contacts is collected to enhance social interaction within the platform.</p>

<p><strong>App Interactions:</strong> Data about how you interact with the App is collected to improve user experience and App functionality.</p>

<p><strong>In-App Search History:</strong> Information about in-app searches is gathered to enhance search functionalities.</p>

<p><strong>Installed Apps:</strong> Data about the apps installed on your device is collected to provide relevant recommendations.</p>

<p><strong>Other User-Generated Content:</strong> Any other user-generated content not listed herein, such as user bios, notes, or open-ended responses, is gathered for diverse user experiences.</p>

<p><strong>Other User Actions:</strong> Data regarding various user activities or in-app actions not explicitly listed is collected to enhance the user experience.</p>

<p><strong>Crash Logs:</strong> We collect crash log data, including crash frequency, stack traces, and crash-related information, to improve app stability.</p>

<p><strong>Diagnostics:</strong> Data concerning app performance, such as battery life, loading times, latency, framerate, and technical diagnostics, is collected to optimize app functionality.</p>

<p><strong>Other App Performance Data:</strong> Any other app performance data not explicitly listed is collected for the continuous improvement of app performance.</p>

<p><strong>2. Purpose of Data Collection</strong></p>

<p>We collect and process your data for the following purposes:</p>

<ul>
  <li><strong>Providing Services:</strong> We use your data to provide and improve our services, including personalization, communication, and user support.</li>
  <li><strong>Messaging:</strong> We collect messages to facilitate in-app and email communication within our platform.</li>
  <li><strong>Visual Content:</strong> User-generated photos and videos enhance visual experiences within our services.</li>
  <li><strong>File Management:</strong> User files and documents are collected to provide storage and document management functionalities.</li>
  <li><strong>Calendar Management:</strong> Calendar events support event scheduling and reminders.</li>
  <li><strong>Contact Interaction:</strong> Data related to your contacts enhances social interaction within the platform.</li>
  <li><strong>App Experience:</strong> We collect data on app interactions, search history, and installed apps to enhance your experience and provide relevant recommendations.</li>
  <li><strong>App Performance:</strong> App-related data, including crash logs, diagnostics, and performance metrics, is collected to improve app stability and functionality.</li>
</ul>
`,
        },
        {
          title: "4.0. Collection of Information",
          content: `<p>Tibalink collects your personal information with your knowledge and consent when you do the following (take note that this list is not exhaustive):</p>
<ol>
  <li>Where you provide the personal data directly to us when you communicate with us over the phone, email or social media;</li>
  <li>Participate in our tendering or recruitment process;</li>
  <li>Participate in our surveys or questionnaires or research activity;</li>
  <li>Sign up to receive marketing;</li>
  <li>When accessing our services or when providing us with your goods or services;</li>
  <li>Completing a form, security check, or events attendance lists to participate in events organized by Tibalink and using or registering to use our website.</li>
  <li>From third parties, such as your references, the business entities that you work for, your former colleges, former schools, and higher education institutions, regulatory or professional bodies, and government departments/agencies.</li>
  <li>From publicly available sources.</li>
</ol>`,
        },
        {
          title: "5.0 . Use of Information",
          content: `<p>We may use personal data provided to us for any of the following purposes, but are not limited to:</p>
<ol>
  <li>Confirming your identity and communicating with you;</li>
  <li>Managing and improving our relationship with you;</li>
  <li>Making decisions about procuring your services;</li>
  <li>Undertaking contract, supply, financial management, planning, and reporting within our business;</li>
  <li>Enabling third parties to deliver products or services on our behalf;</li>
  <li>Managing, administering, and improving our business, client, and service provider engagements and relationships;</li>
  <li>Evaluating recruitment applications for employment with us and undertaking pre-employment screening;</li>
  <li>Fulfilling and monitoring our responsibilities under the various laws of Kenya;</li>
  <li>Communicating effectively with you by post, email, and phone;</li>
  <li>Complying with our obligations to donors and sponsors;</li>
  <li>Managing and maintaining your information in hard copy records, files, and systems;</li>
  <li>For business contingency planning and response to active incidents;</li>
  <li>Establishing, exercising, and/or defending legal claims or rights;</li>
  <li>Investigating and responding to complaints or incidents;</li>
  <li>Auditing, monitoring, investigation, and compliance monitoring activities in relation to our policies;</li>
  <li>Meeting our obligations to, cooperating with, responding to requests from, complying with lawful requests by public authorities or where otherwise required;</li>
  <li>Conducting compliance activities;</li>
  <li>Recording and/or monitoring telephone conversations to maintain service quality and security;</li>
  <li>For research and other statistical and trend analysis;</li>
  <li>Complying with applicable laws and regulations.</li>
</ol>`,
        },
        {
          title: "6.0 . Legal Basis for processing your information",
          content: `<p>We will process your personal information based on any of the lawful bases provided for under the Kenya Data Protection Act, 2019:</p>
<ol>
  <li>Your consent. We might use your information where you have given your consent to us, i.e., (i) to send marketing communication (unless applicable law permits us to contact you without prior consent); (ii) to communicate with you and manage your participation in our contests, offers, referrals, or promotions. Please note that although we may also process your personal data for marketing purposes when applicable law permits us to contact you without your separate consent, if you choose not to receive marketing communication from us (i.e., if you opt-out), we will honor your request.</li>
  <li>The information provided might be required for the performance of a contract, i.e., (i) to provide Services and customer support; (ii) to process your transactions; (iii) to ensure the secure, reliable, and robust performance of our Services and Website.</li>
  <li>Where processing is necessary for the purposes of legitimate interests pursued by Tibalink or by a third party within the confines of the law e.g (i) to properly administer communication with you; (ii) to detect, prevent, or otherwise address fraud, abuse, security, or technical issues with our services and Website; (iii) to protect against harm to the rights, property, and safety of our Customers, end users, or third parties; (iv) to improve or maintain our Services and provide new products and features; (v) to receive knowledge of how our Website and application are being used.</li>
  <li>Compliance with a legal obligation to which we are subject to;</li>
  <li>Performance of any task carried out by a public authority;</li>
  <li>Public interest;</li>
  <li>To protect your vital interest or the vital interests of any person.</li>
</ol>`,
        },
        {
          title: "7.0 . Individual Rights",
          content: `<p>Subject to certain exceptions and limitations, you have the following rights in relation to your personal data:</p>
<ol>
  <li>Right to be informed that we are collecting personal data about you;</li>
  <li>Right to be informed of the use to which your personal data is to be put;</li>
  <li>Right to access your personal data in our custody;</li>
  <li>Right to correction and deletion of false or misleading data about you in our custody;</li>
  <li>Right to deletion of personal data about you in our custody;</li>
  <li>Right to request the restriction of processing of your personal data;</li>
  <li>Right to request us to transfer personal data either to you or to another company in a commonly used electronic format;</li>
  <li>Right to object to the processing of all or part of your personal data;</li>
  <li>Right to object and opt-out of our direct marketing services;</li>
  <li>Right to request not to be subject to automated decision making.</li>
</ol>
<p>If you wish to exercise any of the rights set out above, please contact us at <a href="mailto:tibalink@qhala.com">tibalink@qhala.com</a>.</p>`,
        },
        {
          title: "8.0 . Data Sharing",
          content: `<p>We may share your personal information to the extent necessary with trusted third parties for specific purposes that enhance our services or are required for legal and operational reasons. These third parties may include:</p>
<ol>
  <li>Our partner organizations;</li>
  <li>External service providers;</li>
  <li>Funders and sponsors;</li>
  <li>Professional service providers such as accountants, auditors, insurers, and tax advisers;</li>
  <li>Legal advisors, government and law enforcement authorities;</li>
  <li>Competent regulatory, prosecuting, tax, or governmental authorities;</li>
  <li>Other persons where disclosure is required by law;</li>
  <li>Any other relevant professional or statutory regulatory bodies;</li>
  <li>Any other person you have authorized us by your consent to share your personal data with.</li>
</ol>
<p>Cross-border transfers of personal data:</p>
<p>To facilitate our Services and Website, we may store, access, and transfer personal data from around the world, including in countries where we have operations. These locations may not guarantee the same level of protection of personal data as the one in which you reside. We assess the circumstances involving all cross-border data transfers and have suitable safeguards in place to require that your personal data will remain protected in accordance with this Privacy Policy.</p>
`,
        },
        {
          title: "9.0 . Account Deletion",
          content: `<p>You may choose to exercise your right to delete your personal data in our custody in accordance with this policy.
             We have provided a link here <a href="/deleteaccount">[DeleteAccount]</a> where you can opt for your account to be deleted.
              Alternatively, you can email us at <a href="mailto:tibalink@qhala.com">tibalink@qhala.com</a>.</p>
`,
        },
        {
          title: "10.0 . Third-Party Information Collection",
          content: `<p>We will only retain your personal data for as long as necessary to accomplish the purposes for which it was collected, including complying with any legal, accounting, or reporting requirements.</p>
<p>We may retain your personal data (or personal data you provide) for a longer period if the retention is required or authorized by law, reasonably necessary for a lawful purpose, authorized or consented by you, for personal data that has been anonymized or for historical, statistical, journalistic, literature, and art of research purposes.</p>
<p>Information about you that has been anonymized may be held indefinitely.</p>
`,
        },
        {
          title: "11.0 .Data Security",
          content: `<p>We have put in place appropriate physical and technical measures to safeguard your personal data from being accidentally lost, used, or accessed in an unauthorized way.</p>

<p><strong>Physical Measures:</strong> Our servers are located in certified data centers with appropriate physical control measures in place.</p>

<p><strong>Technical Measures:</strong> We use layered defense with firewalls, anti-malware protection, intrusion detection, and prevention systems. Our infrastructure is regularly updated, and regular vulnerability scans are in place to detect possible vulnerabilities. We have security event and incident management solutions to correlate and investigate signals in the cyber network. Servers are hardened, and automated configuration tools are used to manage them. All workplaces are managed from a centralized endpoint management tool. Data at rest and in transit are encrypted. Encryption protocols are used according to the newest security practices.</p>

<p><strong>Organizational Measures:</strong> We adopted information security and data processing policies according to the best practices.</p>

<p>However, no company can guarantee the absolute security of internet communications as no technology is completely bulletproof. By using the Services and Website, you expressly acknowledge that we cannot guarantee the 100% security of personal data provided to or received by us through the Services and that any information received from you through Websites or our Services is provided at your own responsibility. If you have any reason to believe that your interaction with us is no longer secure, please notify us at <a href="mailto:tibalink@qhala.com">tibalink@qhala.com</a>.</p>
`,
        },
        {
          title: "12.0 . Limitation of Liability",
          content: `<p>To ensure the security of personal data, we apply various technical, physical, and organizational security measures; however, it is your responsibility to exercise caution and reasonableness when using the Services and Website. You will be personally liable if your use of the Services or Website violates any third-party privacy, any other rights or any applicable laws. Under no circumstances are we liable for the consequences of your unlawful, willful, and negligent activities, and any circumstances that may not have been reasonably controlled or foreseen.</p>`,
        },
        {
          title: "13.0 . Minors’ Data",
          content: `<p>We do not knowingly collect or solicit personal data from anyone under the age of 18 without parental or guardian consent. If you are under 18, please do not attempt to send any personal data about yourself to us. If we acknowledge that we have collected and processed personal data from a minor under the age of 18, we will delete that data as quickly as possible.
</p>`,
        },
        {
          title: "14.0	Links to other websites ",
          content: `<p>Our Website may include links to other websites (e.g., social media websites) whose privacy practices may be different from ours. If you access any of those websites via such links and/or submit your personal data to any of those websites, your personal data is processed by the procedures established by those third parties and governed by their privacy policies. We encourage you to carefully read the privacy policy (or other respective privacy notices) of any website you visit.</p>`,
        },
        {
          title: "15.0  The Use of Cookies ",
          content: `<p>We will occasionally use a “cookie” and/or other similar technologies to place certain information on your computer’s hard drive when you visit our website or any of the Hospital’s affiliated websites. This enables us recognize you during subsequent visits. Cookies do not enable us to gather personal data about you unless you give the information to our server.</p> `,
        },
        {
          title: "16.0  Contact Us ",
          content: `<p>If you have any questions about this Privacy Policy and how we handle your personal data, please send us an email at <a href="mailto:tibalink@qhala.com">tibalink@qhala.com</a>.</p> `,
        },
        {
          title: "17.0  Updates to the Privacy Policy ",
          content: `<p>We reserve the right to amend or modify this Privacy Policy at any time and we shall notify you of the changes through electronic mail or such other means of communication which may be available to us. Any amendment or modification to this policy will take effect from the date of notification on the Tibalink website.
Updates to the Privacy Policy. If the amendments to the Privacy Policy materially affect the activities of our processing of your personal data, we will notify you in advance of such changes by reasonable means (e.g., notification through the respective applications, our Website, or via email), and we will always indicate the date of the last update. Unless it is stated by us otherwise, each update of the Privacy Policy comes into force as of the moment the amended Privacy Policy is published on this Website. You are expected to check this Privacy Policy regularly so that you are familiar with the most current wording of the Privacy Policy. Your continued use of the Services and Website will be deemed acceptance thereof.
</p> `,
        },
      ],
      isOpen: [],
      email: "",
      formActionUrl:
        "https://tibalink.us13.list-manage.com/subscribe/post?u=4484f072bf6c3c4fcc5bd0807&id=b165551d9d&f_id=009537e2f0",
      responseHtml: "",
      isCollapsed: true,
    };
  },
  created() {
    // Initialize isOpen based on the number of accordion items
    this.isOpen = new Array(this.accordionItems.length).fill(false);
  },
  methods: {
    termsofservice() {
      this.$router.push({ name: "termsofservice" });
    },
    privacypolicy() {
      this.$router.push({ name: "privacypolicy" });
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleAccordion(index) {
      // Toggle the state of the clicked item and close others
      this.isOpen = this.isOpen.map((state, i) =>
        i === index ? !state : false
      );
    },
    home() {
      this.$router.push({ name: "home" });
    },
    features() {
      this.$router.push({ name: "home", params: { id: "#about" } });
    },
    about() {
      this.$router.push({ name: "home", params: { id: "#features" } });
    },
    deleteAcount() {
      this.$router.push({ name: "deleteaccount" });
    },
    submitForm() {
      console.log("submitting   ");
      fetch(this.$refs.form.action, {
        method: "POST",
        body: new FormData(this.$refs.form),
      })
        .then((response) => response.text()) // Read the response as HTML
        .then((htmlData) => {
          // Set the response HTML to your component's data property
          this.responseHtml = htmlData;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.collapse:not(.show) {
  display: none;
}
.accordion-collapse {
  border-bottom-right-radius: var(--si-accordion-border-radius);
  border-bottom-left-radius: var(--si-accordion-border-radius);
}
.nav-link {
  color: #33354d;
}
.nav-link:hover {
  color: #1dd3b0; /* Your hover color here */
}
.page-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.4s 0.2s ease-in-out;
  transition: all 0.4s 0.2s ease-in-out;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
}

.dark-mode .page-loading {
  background-color: #0b0f19;
}

.page-loading.active {
  opacity: 1;
  visibility: visible;
}

.page-loading-inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.page-loading.active > .page-loading-inner {
  opacity: 1;
}

.page-loading-inner > span {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  color: #9397ad;
}

.dark-mode .page-loading-inner > span {
  color: #fff;
  opacity: 0.6;
}

.page-spinner {
  display: inline-block;
  width: 2.75rem;
  height: 2.75rem;
  margin-bottom: 0.75rem;
  vertical-align: text-bottom;
  border: 0.15em solid #b4b7c9;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner 0.75s linear infinite;
  animation: spinner 0.75s linear infinite;
}

.dark-mode .page-spinner {
  border-color: rgba(255, 255, 255, 0.4);
  border-right-color: transparent;
}

@-webkit-keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.card-color {
  background-color: #1abe9e !important;
}

.border-color {
  border-color: #1abe9e !important;
}

.shadow-color {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(69, 178, 176, 0.9) !important;
}

.card-color {
  background-color: #1abe9e !important;
}

.border-color {
  border-color: #1abe9e !important;
}

.shadow-color {
  box-shadow: 0 0.5rem 1.125rem -0.5rem rgba(69, 178, 176, 0.9) !important;
}

.btn:hover {
  background-color: #006466 !important;
  border: solid 1px #006466 !important;
  color: #ffff;
}

.text-color-blue {
  color: #006466 !important;
}

.text-color-blue {
  color: #006466 !important;
}

.text-color-blue-light {
  color: #1dd3b0 !important;
}
</style>
