<template>
  <div class="container">
    <div class=" clomun1">
      <!-- <img src="../assets/img/deleteAc/delete1.png" /> -->
    </div>
    <div class="column">
      <div>
        <div class="canceling" @click="gotoprivacy">
          <img src="../assets/img/deleteAc/x-close.png" />
        </div>
        <div class="introtext">
          Are you sure you want to delete your account ?
        </div>
        <div class="cancelingteext">
          <span class="deleteprompt"
            >By deleting your account you will loose your data and will no
            longer be able to access Tibalink. The following data types will be
            affected:<br /><br /></span
          ><span class="deletelist">
            <ul>
              <li>Personal information</li>
              <li>Messages</li>
              <li>Audio files</li>
              <li>Files and documents</li>
              <li>Contacts</li>
            </ul></span
          ><span class="intro"
            ><br />Once you click on start, we will take a 3-step process to
            delete your account.<br /><br /></span
          ><span class="step1">Step 1:</span
          ><span class="step1text">
            Feedback collection- we would like to know we can better improve our
            services.<br /><br /></span
          ><span class="step1">Step 2: </span
          ><span class="step1text"
            >Experience rating- we would like to know how your overall
            experience has been with our services.<br /><br /></span
          ><span class="step1">Step 3:</span
          ><span class="step1text">
            Account verification - we confirm your account details.<br /><br />Upon
            completion, we will take 1-2 working days to review your
            application. <br
          /></span>
          <div class="startbtn">
            <a
              @click="gotnext"
              class="btn btn-hov btn-primary btn-lg"
              style="
                background-color: rgb(29, 211, 176);
                box-shadow: 2px 2px 5px rgba(29, 211, 176, 0.5);
              "
            >
              Start
            </a>
          </div>
        </div>
        <div class="logo"><img src="../assets/img/logo.svg" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isScrollDisabled: false,
    };
  },
  methods: {
    gotnext() {
      this.$router.push({ name: "reviewpage" });
    },
    gotoprivacy() {
      this.$router.push({ name: "privacypolicy" });
    },
    disableScroll() {
      this.isScrollDisabled = true;
    },
    enableScroll() {
      this.isScrollDisabled = false;
    }
  },
};
</script>

<style scoped>

@media (min-width: 991px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
    gap: 20px;
  }

  .column {
  }
  .clomun1{
    background: url("../assets/img/deleteAc/delete1.png");
  }
  .canceling {
    padding-top: 4%;
    padding-left: 85%;
    padding-bottom: 4%;
  }
  .deletepage {
    overflow: hidden;
    padding-left: 10%;
    padding-right: 10%;
  }
  .logo {
    padding-left: 30%;
    padding-top: 11.5%;
  }
  .startbtn {
    padding-top: 10%;
    padding-left: 80%;
  }
  .deletepage {
    height: 100vh;
    overflow: hidden;
  }
  .introtext {
    width: 100%;
    font-size: 18px;
    color: black;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }
  .deleteprompt {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
  .deletelist {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
  .step1text {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
  .step1 {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }
  .intro {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
}
@media (max-width: 991px) {
  .canceling {
    padding-top: 4%;
    padding-left: 85%;
    padding-bottom: 4%;
  }
  .deletepage {
    overflow: hidden;
    padding-left: 10%;
    padding-right: 10%;
  }
  .logo {
    padding-left: 30%;
    padding-top: 5%;
  }
  .startbtn {
    padding-top: 10%;
    padding-left: 80%;
  }
  .deletepage {
    height: 100vh;
    overflow: hidden;
  }
  .introtext {
    width: 100%;
    font-size: 18px;
    color: black;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }
  .deleteprompt {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
  .deletelist {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
  .step1text {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
  .step1 {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }
  .intro {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
}
@media (max-width: 675px) {
  .canceling {
    padding-top: 4%;
    padding-left: 85%;
    padding-bottom: 4%;
  }
  .deletepage {
    overflow: hidden;
    padding-left: 10%;
    padding-right: 10%;
  }
  .logo {
    padding-left: 30%;
    padding-top: 5%;
  }
  .startbtn {
    padding-top: 10%;
    padding-left: 65%;
    color:red;
  }
  .deletepage {
    height: 100vh;
    overflow: hidden;
  }
  .introtext {
    width: 100%;
    font-size: 18px;
    color: black;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }
  .deleteprompt {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
  .deletelist {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
  .step1text {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
  .step1 {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }
  .intro {
    color: #232735;
    font-size: 18px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
  }
}




</style>
