import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import deleteAccount from "../views/deletionView.vue";
import reviewPage from "../views/reviewView.vue";
import ratingPage from "../views/ratingView.vue";
import ootpPage from "../views/ooptView.vue";
import accountdeletepage from "../views/accountdelView.vue";
import termsofservice from "../views/termsofService.vue"
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/deleteaccount",
    name: "deleteaccount",
    component: deleteAccount,
  },
  {
    path: "/review",
    name: "reviewpage",
    component: reviewPage,
  },
  {
    path: "/rating",
    name: "ratingpage",
    component: ratingPage,
  },
  {
    path: "/sendconfirmation",
    name: "ootpPage",
    component: ootpPage,
  },
  {
    path: "/accountdelete",
    name: "accountdelete",
    component: accountdeletepage,
  },
  {
    path: "/termsofservice",
    name: "termsofservice",
    component: termsofservice,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
