<template>
  <div class="accordion-item border-0 rounded-3 shadow-sm mb-3">
    <h3 class="accordion-header">
      <button
        :class="accordionButtonClass"
        type="button"
        @click="toggleAccordion"
        data-bs-toggle="collapse"
        data-bs-target="#q-12"
        aria-controls="q-12"
        :aria-expanded="isExpanded"
        style="animation: myButtonAnimation 1s ease-in-out"
      >
        {{ item.title }}
      </button>
    </h3>
    <div class="accordion-collapse collapse" :class="{ show: isExpanded }">
      <div class="accordion-body fs-sm pt-0">
        <p v-html="item.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object, // The accordion item object with title and content
    isExpanded: Boolean, // Indicates whether this item is expanded
  },
  computed: {
    accordionButtonClass() {
      return {
        "accordion-button": true,
        "shadow-none": true,
        "rounded-3": true,
        collapsed: !this.isExpanded,
      };
    },
  },
  methods: {
    toggleAccordion() {
      this.$emit("toggleAccordion"); // Emit an event to notify the parent component
    },
  },
};
</script>
