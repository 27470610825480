import axios from "axios";

import CryptoJS from 'crypto-js';


export const tibalinkapi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
});

export  function encrypt(object) {
  const keyBase64 = process.env.VUE_APP_ENCRYPT_KEY;
  var key = CryptoJS.enc.Base64.parse(keyBase64);
  var srcs = CryptoJS.enc.Utf8.parse(object);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}
