<template>
  <div class="container">
    <div class="column column1"></div>
    <div class="column">
      <div>
        <div class="canceling" @click="gotoprivacy">
          <img src="../assets/img/deleteAc/x-close.png" />
        </div>
        <div class="sorrytext">
          We're sorry to see you go! Your feedback is valuable to us. Please
          take a moment to let us know the primary reason for leaving Tibalink
        </div>
        <div class="inputfield">
          <div class="icons">
            <div @click="clickIcon1" v-if="!selectedOption1" class="imagepos">
              <img src="../assets/img/deleteAc/empty.png" />
            </div>
            <div @click="clickIcon1" v-if="selectedOption1" class="imagepos">
              <img src="../assets/img/deleteAc/clicked.png" />
            </div>
            <div class="optionaltext">
              <div class="choicetext">Concerns about Data Privacy</div>
            </div>
          </div>
          <div class="icons">
            <div @click="clickIcon2" v-if="!selectedOption2" class="imagepos">
              <img src="../assets/img/deleteAc/empty.png" />
            </div>
            <div @click="clickIcon2" v-if="selectedOption2" class="imagepos">
              <img src="../assets/img/deleteAc/clicked.png" />
            </div>
            <div class="optionaltext">
              <div class="choicetext">Unwanted Notifications</div>
            </div>
          </div>
          <div class="icons">
            <div @click="clickIcon3" v-if="!selectedOption3" class="imagepos">
              <img src="../assets/img/deleteAc/empty.png" />
            </div>
            <div @click="clickIcon3" v-if="selectedOption3" class="imagepos">
              <img src="../assets/img/deleteAc/clicked.png" />
            </div>
            <div class="optionaltext">
              <div class="choicetext">Switching to Another Service</div>
            </div>
          </div>
          <div class="icons">
            <div @click="clickIcon4" v-if="!selectedOption4" class="imagepos">
              <img src="../assets/img/deleteAc/empty.png" />
            </div>
            <div @click="clickIcon4" v-if="selectedOption4" class="imagepos">
              <img src="../assets/img/deleteAc/clicked.png" />
            </div>
            <div class="optionaltext">
              <div class="choicetext">Not Satisfied with Services</div>
            </div>
          </div>
          <div class="others">
            <div @click="clickIcon5" v-if="!selectedOption5" class="imagepos">
              <img src="../assets/img/deleteAc/empty.png" />
            </div>
            <div @click="clickIcon5" v-if="selectedOption5" class="imagepos">
              <img src="../assets/img/deleteAc/clicked.png" />
            </div>
            <div class="optionaltext">
              <div class="choicetext">Other (Please Specify)</div>
            </div>
          </div>
          <div class="otherstext" v-if="selectedOption5">
            <textarea
              id="paragraphInput"
              placeholder="Write something here..."
              name="paragraph"
              rows="3"
              cols="50"
              v-model="reason"
            ></textarea>
          </div>
        </div>
        <div class="nav">
          <div class="navprev">
            <img src="../assets/img/deleteAc/right.png" @click="gotoprev" />
            <div class="navtext" @click="gotoprev">Back</div>
          </div>
          <div class="navnext">
            <div class="navtext" @click="saveStep1Data">Proceed</div>
            <img src="../assets/img/deleteAc/left.png" @click="gotnext" />
          </div>
        </div>
        <div class="logo"><img src="../assets/img/logo.svg" /></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedOption1: false,
      selectedOption2: false,
      selectedOption3: false,
      selectedOption4: false,
      selectedOption5: false,
      reason: "",
    };
  },
  methods: {
    clickIcon1() {
      this.selectedOption1 = !this.selectedOption1;
      this.selectedOption2 = false;
      this.selectedOption3 = false;
      this.selectedOption4 = false;
      this.selectedOption5 = false;
      this.reason = "Concerns about Data Privacy";
    },
    clickIcon2() {
      this.selectedOption2 = !this.selectedOption2;
      this.selectedOption1 = false;
      this.selectedOption3 = false;
      this.selectedOption4 = false;
      this.selectedOption5 = false;
      this.reason = "Unwanted Notifications";
    },
    clickIcon3() {
      this.selectedOption3 = !this.selectedOption3;
      this.selectedOption2 = false;
      this.selectedOption1 = false;
      this.selectedOption4 = false;
      this.selectedOption5 = false;
      this.reason = "Switching to Another Service";
    },
    clickIcon4() {
      this.selectedOption4 = !this.selectedOption4;
      this.selectedOption2 = false;
      this.selectedOption3 = false;
      this.selectedOption1 = false;
      this.selectedOption5 = false;
      this.reason = "Not Satisfied with Services";
    },
    clickIcon5() {
      this.selectedOption5 = !this.selectedOption5;
      this.selectedOption2 = false;
      this.selectedOption3 = false;
      this.selectedOption4 = false;
      this.selectedOption1 = false;
    },
    gotnext() {
      this.$store.dispatch("reason", { data: this.reason });
      this.$router.push({ name: "ratingpage" });
    },
    gotoprev() {
      this.$router.push({ name: "deleteaccount" });
    },
    gotoprivacy() {
      this.$router.push({ name: "privacypolicy" });
    },
    saveStep1Data() {
      this.$store.commit("setStep1Data", this.reason, { root: true });
      this.$router.push({ name: "ratingpage" });
    },
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .container {
    display: flex;
    align-items: stretch;
    gap: 20px;
  }

  .column {
    flex: 1;
    padding: 20px;
  }
  .column1 {
    background: url("../assets/img/deleteAc/reason.png");
  }
  .column2 {
    height: 100%;
  }
  .logo {
    margin-top: 27.5%;
    margin-left: 33%;
  }

  .navprev {
    display: flex;
  }

  .navnext {
    margin-left: 30%;
    display: flex;
  }

  .nav {
    display: flex;
    margin-left: 5%;
    margin-top: 30%;
  }

  .navtext {
    color: #006466;
    font-size: 28.99px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .otherstext {
    margin-left: 10%;
  }

  .others {
    margin: 5%;
    position: relative;
  }

  .icons {
    margin: 5%;
  }

  .choicetext {
    margin-left: 5%;
    color: black;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .optionaltext {
    margin-left: 10%;
  }

  .imagepos {
    position: absolute;
  }

  .inputfield {
    margin-left: 5%;
    width: 100%;
    height: auto;
  }

  .canceling {
    margin-left: 80%;
    margin-top: 5%;
  }

  .sorrytext {
    margin: 5%;
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .textarea {
  }
  .choicetext:hover {
    font-size: 20px;
  }
  .navtext:hover {
    color:rgb(69,211,176)
  }
  .navnext:hover {
    color:rgb(69,211,176)
  }
  .navprev:hover {
    color:rgb(69,211,176)
  }
}
@media (max-width: 992px) {
  .inputfield {
    margin-left: 40%;
  }
  .column2 {
    height: 100%;
  }
  .logo {
    margin-top: 20%;
    margin-left: 33%;
  }

  .navprev {
    display: flex;
  }

  .navnext {
    margin-left: 55%;
    display: flex;
  }

  .nav {
    display: flex;
    margin-left: 5%;
    margin-top: 30%;
  }

  .navtext {
    color: #006466;
    font-size: 28.99px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .otherstext {
    margin-left: 10%;
  }

  .others {
    margin-left: 10%;
    position: relative;
  }

  .icons {
    margin-bottom: 5%;
    margin-left: 10%;
  }

  .choicetext {
    margin-left: 5%;
    color: black;
    font-size: 24px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .optionaltext {
    margin-left: 10%;
  }

  .imagepos {
    position: absolute;
  }

  .inputfield {
    margin-left: 5%;
    width: 100%;
    height: auto;
  }

  .canceling {
    margin-left: 80%;
    margin-top: 5%;
  }

  .sorrytext {
    margin: 5%;
    color: black;
    font-size: 32px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .textarea {
  }
  .choicetext:hover {
    font-size: 20px;
  }
  .navtext:hover {
    color:rgb(69,211,176)
  }
  .navnext:hover {
    color:rgb(69,211,176)
  }
  .navprev:hover {
    color:rgb(69,211,176)
  }
}
@media (max-width: 645px) {
  #paragraphInput {
    width: 90%;
    height: 100%;
  }
  .inputfield {
    margin-top: 25%;
    margin-left: 40%;
  }
  .column2 {
    height: 100%;
  }
  .logo {
    margin-top: 15%;
    margin-left: 32%;
  }

  .navprev {
    display: flex;
  }

  .navnext {
    margin-left: 27%;
    display: flex;
  }

  .nav {
    display: flex;
    margin-left: 5%;
    margin-top: 55%;
  }

  .navtext {
    color: #006466;
    font-size: 28.99px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .otherstext {
    margin-left: 10%;
  }

  .others {
    margin-left: 10%;
    position: relative;
  }

  .icons {
    margin-bottom: 5%;
    margin-left: 10%;
  }

  .choicetext {
    margin-left: 5%;
    color: black;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .optionaltext {
    margin-left: 10%;
  }

  .imagepos {
    position: absolute;
  }

  .inputfield {
    margin-left: 5%;
    width: 100%;
    height: auto;
  }

  .canceling {
    margin-left: 80%;
    margin-top: 5%;
  }

  .sorrytext {
    margin: 5%;
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .textarea {
  }
  .choicetext:hover {
    font-size: 20px;
  }
  .navtext:hover {
    color:rgb(69,211,176)
  }
  .navnext:hover {
    color:rgb(69,211,176)
  }
  .navprev:hover {
    color:rgb(69,211,176)
  }
}
@media (max-width: 400px) {
  #paragraphInput {
    width: 90%;
    height: 100%;
  }
  .inputfield {
    margin-top: 0%;
    margin-left: 0%;
  }
  .column2 {
    height: 100%;
  }
  .logo {
    margin-top: 20%;
    margin-left: 33%;
  }

  .navprev {
    display: flex;
  }

  .navnext {
    margin-left: 10%;
    display: flex;
  }

  .nav {
    display: flex;
    margin-left: 5%;
    margin-top: 15%;
  }

  .navtext {
    color: #006466;
    font-size: 28.99px;
    font-family: Manrope;
    font-weight: 600;
    word-wrap: break-word;
  }

  .otherstext {
    margin-top:7%;
    margin-left: 10%;
  }

  .others {
    margin-left: 10%;
    position: relative;
  }

  .icons {
    margin-bottom: 5%;
    margin-left: 10%;
  }

  .choicetext {
    margin-left: 5%;
    color: black;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .optionaltext {
    margin-left: 10%;
  }

  .imagepos {
    position: absolute;
  }

  .canceling {
    margin-left: 80%;
    margin-top: 5%;
  }

  .sorrytext {
    margin: 5%;
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 500;
    word-wrap: break-word;
  }

  .textarea {
  }
  .choicetext:hover {
    font-size: 20px;
  }
  .navtext:hover {
    color:rgb(69,211,176)
  }
  .navtext:hover {
    color:rgb(69,211,176)
  }
  .navnext:hover {
    color:rgb(69,211,176)
  }
  .navprev:hover {
    color:rgb(69,211,176)
  }
}
</style>
